import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';

export const GlobalRadio = (props) => {
    const {label, value, ...passThroughProps} = props

    return (
        <Box
            sx={ {display: 'flex'} }
        >
            <Radio
                value={ value }
                name={ `radio-button-${value}` }
                inputProps={ {'aria-label': label || value} }
                { ...passThroughProps }
            />
            <p>{label}</p>
        </Box>
    )
}

GlobalRadio.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button';
import {ROUND_HOVER_CHANGE_BUTTON, ROUND_HOVER_CHANGE_BUTTON_INVERSE, ROUND_HOVER_CHANGE_ICON_BUTTON, ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_DARK} from '../../styles/buttons';

const buttonBrandFeaturesByType = (format) => {
    switch (format) {
        case 'primary':
            return {
                variant: 'contained',
                style: {}
            }
        case 'secondary':
            return {
                variant: 'outlined',
                style: {'backgroundColor': '#ffffff'}
            }
        case 'tertiary':
            return {
                variant: 'text',
                style: {'textDecoration': 'underline'}
            }
        case 'text':
            return {
                variant: 'text',
                style: {'fontWeight': 900, 'fontSize': 18}
            }
        case 'roundIconHover':
            return {
                variant: 'text',
                style: ROUND_HOVER_CHANGE_ICON_BUTTON
            }
        case 'roundIconHover-focus':
            return {
                variant: 'text',
                style: ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_DARK
            }
        case 'roundHover':
            return {
                variant: 'text',
                style: ROUND_HOVER_CHANGE_BUTTON
            }
        case 'roundHover-focus':
            return {
                variant: 'text',
                style: ROUND_HOVER_CHANGE_BUTTON_INVERSE
            }
        case 'custom':
            return {
                variant: 'text',
                style: {}
            }
    }
}

export const GlobalButton = (props) => {
    const {format, text, style, ...passThroughProps} = props
    const brandedButtonFeatures = buttonBrandFeaturesByType(format);
    const passThroughFeatures = {
        variant: brandedButtonFeatures.variant,
        ...passThroughProps
    }

    const buttonStyle = {
        borderRadius: 5,
        ...brandedButtonFeatures.style,
        ...style
    }

    return <Button {...passThroughFeatures} sx={buttonStyle}> {text} </Button>
}

GlobalButton.propTypes = {
    format: PropTypes.string.isRequired,
    style: PropTypes.object,
    text: PropTypes.string.isRequired,
}

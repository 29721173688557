import checkLicense from './license';

export function enableVideo(userData, billingInformation) {

    if(!billingInformation) {
        return false;
    }

    if(Object.keys(billingInformation).length === 0) {
        return false;
    }

    if(Number(billingInformation.data.licenses) ===  0 || billingInformation.data.usedLicenses.length === 0) {
        return false;
    }

    return checkLicense(userData.id, billingInformation.data.usedLicenses);
}
export const permissions = {
    ManageBilling: 'manage-billing',
    ManageUsers: 'manage-users',
    ViewAssesmentResults: 'view-assessments-results'
}

export const checkPermission = (userPermissions, requestPermissions) => {
    // If either are null or no length then cant determine access
    if(!userPermissions || !userPermissions.length 
        || !requestPermissions) {
        return false;
    }

    const finalPermissionsToCheck = Array.isArray(requestPermissions) 
        ? requestPermissions 
        : [requestPermissions];

    return finalPermissionsToCheck.filter(permission => userPermissions.some(up => up === permission)).length > 0;
}

export const hasAbility = (publicLoad, evaluatePermissions, permissionsToCheck, currentUser, institutionId) => {
   // Anything public should never be able to have abilities on profiles
    if(publicLoad) {
        return false;
    }

    if(evaluatePermissions) {
        const {institutionData} = currentUser;
        const institution = institutionData.find(inst => inst.id === institutionId);
        return checkPermission(institution.permissions, permissionsToCheck);
    }

    return true;
}
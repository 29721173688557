import React from 'react'
import {Controller} from 'react-hook-form';
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const GlobalFormSelect = (props) => {
  const {name, control, id, label, options, ...passthroughProps} = props

  return (
    <Controller
      name={ name }
      control={ control }
      render={ ({
        field: {onChange, value},
        fieldState: {error},
      }) => (
        <FormControl sx={ {m: 1, minWidth: 80} }>
          <InputLabel id={id}>{ startCase(label || name) }</InputLabel>
          <Select
            labelId={ 'select-label' }
            id={id}
            value={ value }
            label={ label || name }
            onChange={ onChange }
            error={ !!error }
            { ...passthroughProps }>
            { options.map((option, index) => {
              const {name, value} = option;
              return <MenuItem key={ index } value={ value || option }>{ name || option }</MenuItem>
            }) }
          </Select>
        </FormControl>
      ) }
    />
  )
}

GlobalFormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  control: PropTypes.any,
  label: PropTypes.string,
}

import React from 'react';
import {Outlet} from 'react-router-dom';

import PropTypes from 'prop-types'

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const PublicRoute = ({children}) => (
    <Container component="main">
        <CssBaseline />
        { children ? children : <Outlet /> }
    </Container>
)

PublicRoute.propTypes = {
    children: PropTypes.any
}

export default PublicRoute;

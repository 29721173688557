
export {default as logoCircle} from './images/logo_circle.png';
export {default as logoSquare} from './images/logo_square.png';
export {default as landingImage} from './images/landing.svg';
export {default as homeAdmin} from './images/home_admin.svg';
export {default as homeUser} from './images/home_user.svg';

// Examples
export {default as exampleMenu} from './images/example_menu.png';
export {default as exampleWatch} from './images/example_watch.png';
export {default as exampleRatings}  from './images/example_ratings.png';
export {default as exampleResults} from './images/example_results.png';

// Icons
export {default as iconLightBulb} from './images/light_bulb.png';
export {default as iconHammerWrench} from './images/hammer_wrench.png';
export {default as iconRightArrowButton} from './images/right_arrow_button.png'

// placeholder images
export {default as resultsPlaceholder} from './images/placeholder_results.svg';
export {default as videosPlaceholder} from './images/placeholder_videos.svg';
export {default as underConstruction1} from './images/under_construction_1.jpg';
export {default as underConstruction2} from './images/under_construction_2.jpg';
export {default as underConstruction3} from './images/under_construction_3.jpg';

// Career Detail Images
export {default as careerBag} from './images/career_bag.svg';
export {default as careerGradCap} from './images/career_grad_cap.svg';
export {default as careerStar} from './images/career_star.svg';

// Career Cluster Images
export * from './images/career_clusters';

// Insight Report
export * from './images/insight_report';
/* eslint-disable no-console */

import OpenApiAxiosClient from 'axios';
import {CareerDataApi, RatingsApi, ResultSharingApi} from '@futuregenxyz/assessment-api-client';
import {AuthorizationType, AccountApi, BillingApi, PasswordApi, RolesApi, SessionAudience, UserApi, UserType, UserInvitesApi} from '@futuregenxyz/user-api-client';
import {OrganizationApi} from '@futuregenxyz/organization-api-client';
import {VideosApi, WatchLogApi} from '@futuregenxyz/video-api-client';
import {getConfiguration,handleError, handleSuccess} from './helpers';

const audience = SessionAudience.UserWebApp;

OpenApiAxiosClient.interceptors.response.use(res => handleSuccess(res), rej => handleError(rej));

let assessmentApi;
let organizationApi;
let userApi;
let videoApi;

const assessmentApis = () => {

    const assessmentApiApiConfig = getConfiguration('assessment', 9003);

    return {
        careerData: new CareerDataApi(assessmentApiApiConfig, null, OpenApiAxiosClient),
        ratings: new RatingsApi(assessmentApiApiConfig, null, OpenApiAxiosClient),
        resultSharing: new ResultSharingApi(assessmentApiApiConfig, null, OpenApiAxiosClient)
    }
}

const organizationApis = () => {
    const organizationApiConfig = getConfiguration('organization', 9002);

    return { 
        organization: new OrganizationApi(organizationApiConfig, null, OpenApiAxiosClient)
    }
}

const userApis = () => {
    const userApiConfig = getConfiguration('user', 9001);

    return {
        account: new AccountApi(userApiConfig, null, OpenApiAxiosClient),
        billing: new BillingApi(userApiConfig, null, OpenApiAxiosClient),
        password: new PasswordApi(userApiConfig, null, OpenApiAxiosClient),
        roles: new RolesApi(userApiConfig, null, OpenApiAxiosClient),
        user: new UserApi(userApiConfig, null, OpenApiAxiosClient),
        userInvites: new UserInvitesApi(userApiConfig, null, OpenApiAxiosClient)
    }
}

const videoApis = () => {
    const videoApiConfig = getConfiguration('video', 9004);

    return {
        videos: new VideosApi(videoApiConfig, null, OpenApiAxiosClient),
        watchLog: new WatchLogApi(videoApiConfig, null, OpenApiAxiosClient)
    }
}

const types = {
    AuthorizationType,
    UserType
}

const setResetApiAuth = () => {
    assessmentApi = assessmentApis();
    organizationApi = organizationApis();
    userApi = userApis();
    videoApi = videoApis();
}

setResetApiAuth();

export {
    audience,
    types,
    assessmentApi,
    organizationApi,
    userApi,
    videoApi,
    setResetApiAuth
}
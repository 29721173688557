const convertValue = (value) => {
    return Math.round(value * 20);
}

const handleSalaryData = (baseHourly, baseAnnual) => {
    let hourly = baseHourly;
    let annual = baseAnnual;

    if(!hourly && !annual) { 
        return null;
    }

    if(annual && !hourly) {
        hourly = parseFloat(annual / 2080.00);
    }

    if(hourly && !annual) {
        annual = parseFloat(hourly * 2080.00);
    }

    return {
        hourly: hourly,
        annual: annual
    }
}

const returnSalaryData = (salary) => {

    // Salary Object
    /*
        ONET
        {
            "soc_code": "45-0000",
            "annual_10th_percentile": 30010,
            "annual_median": 35520,
            "annual_90th_percentile": 56640,
            "hourly_10th_percentile": 14.43,
            "hourly_median": 17.08,
            "hourly_90th_percentile": 27.23
        }

        BLS
        {
            hourlyAverage: item.hMean,
            annualAverage: item.aMean,

            hourlyTenPercent: item.hPct10,
            annualTenPercent: item.aPct10,
            
            hourlyTwentyFivePercent: item.hPct25,
            annualTwentyFivePercent: item.aPct25,
            
            hourlySeventyFivePercent: item.hPct75,
            annualSeventyFivePercent: item.aPct75,
            
            hourlyNinetyPercent: item.hPct90,
            annualNinetyPercent: item.aPct90
        }
    */

    if(!salary) {
        return [];
    }

    if(salary.annual_median || salary.hourly_median) {
        return [
            {
                key: 'ninetiethPercentile',
                title: 'Top Earners',
                ...handleSalaryData(salary.hourly_90th_percentile, salary.annual_90th_percentile)
            },
            {
                key: 'average',
                title: 'Average',
                ...handleSalaryData(salary.hourly_median, salary.annual_median)
            },
            {
                key: 'tenthPercentile',
                title: '10th Percentile',
                ...handleSalaryData(salary.hourly_10th_percentile, salary.annual_10th_percentile)
            }
        ];
    }
    else {
        return [
            {
                key: 'ninetiethPercentile',
                title: 'Top Earners',
                ...handleSalaryData(salary.hPct90, salary.aPct90)
            },
            {
                key: 'seventyFifthPercentile',
                title: '75th Percentile',
                ...handleSalaryData(salary.hPct75, salary.aPct75)
            },
            {
                key: 'average',
                title: 'Average',
                ...handleSalaryData(salary.hMean, salary.aMean)
            },
            {
                key: 'twentyFifthPercentile',
                title: '25th Percentile',
                ...handleSalaryData(salary.hPct25, salary.aPct25)
            },
            {
                key: 'tenthPercentile',
                title: '10th Percentile',
                ...handleSalaryData(salary.hPct10, salary.aPct10)
            }
        ];
    }
}

module.exports = {
    convertValue,
    returnSalaryData
}
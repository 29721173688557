import React from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

const DEFAULT_CARD_STYLE = {
    borderRadius: 2.5,
};

export const GlobalCard = (props) => {
    const {raised = true, style, headerNodes, mediaAttributes, actionsNode, children} = props
    const {avatar, title, subheader, action, ...headerRest} = headerNodes || {}
    const {mediaComponent = 'img', image, altText = '', ...mediaRest} = mediaAttributes || {}
    const cardStyle = style || DEFAULT_CARD_STYLE
    return (
        <Card raised={raised} sx={cardStyle} >
            {headerNodes && (
                <CardHeader
                    avatar={avatar}
                    title={title}
                    subheader={subheader}
                    action={action}
                    {...headerRest}
                />
            )}
            {mediaAttributes && (
                <CardMedia
                    component={mediaComponent}
                    image={image}
                    alt={altText}
                    {...mediaRest}
                />
            )}
            {children && (
                <CardContent>
                    {children}
                </CardContent>
            )}
            {actionsNode && (
                <CardActions
                    sx={actionsNode.style}>
                    {actionsNode.display}
                </CardActions>
            )}
        </Card>
    )
}

GlobalCard.propTypes = {
    raised: PropTypes.bool,
    style: PropTypes.object,
    headerNodes: PropTypes.shape({
        avatar: PropTypes.node,
        title: PropTypes.node,
        subheader: PropTypes.node,
        action: PropTypes.node
    }),
    mediaAttributes: PropTypes.shape({
        mediaComponent: PropTypes.string,
        image: PropTypes.string,
        altText: PropTypes.string,
    }),
    actionsNode: PropTypes.shape({
        style: PropTypes.object,
        display: PropTypes.node,
    }),
    children: PropTypes.node
}

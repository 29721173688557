import React from 'react'

import Typography from '@mui/material/Typography';

const Display = () => {
    return (
        <>
            <Typography variant='h5' fontWeight={ 900 } gutterBottom>
                Display Preferences
            </Typography>
            <Typography variant='body1'>
                This section under development
            </Typography>
        </>
    )
}

export default Display
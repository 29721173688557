import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const DEFAULT_MODAL_BOX_STYLE = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
};

export const GlobalModal = (props) => {
    const {modalTitle, modalDescription, style, children, ...rest} = props
    const modalStyle = style || DEFAULT_MODAL_BOX_STYLE
    return (
        <Modal
            aria-labelledby={ modalTitle }
            aria-describedby={ modalDescription }
            {...rest}
        >
            <Box sx={ modalStyle }>
                { children }
            </Box>
        </Modal>
    )
}

GlobalModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalDescription: PropTypes.string.isRequired,
    style: PropTypes.object,
    children: PropTypes.node
}

import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Copyright = props => {

  // on mobile devices sometimes the elements on the page change and having the 
  useEffect(() => {
    const footerHeight = document.getElementById('footer').offsetHeight
    document.body.style.paddingBottom = `${footerHeight+35}px`
  }, [])

  return (
    <Typography id='footer' paragraph={true}  color='text.secondary' align='center' sx={ {position: 'fixed', bottom: 0, width: '100%'} } { ...props }>
      { 'Copyright © ' }
      <Link color='inherit' href='https://www.futuregenxyz.com/'>
        Future Gen
      </Link>{ ' ' }
      { new Date().getFullYear() }
      { '.' }
    </Typography>
  )
}

Copyright.propTypes = {
  props: PropTypes.object
}

export default Copyright

import React from 'react'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Image} from 'mui-image'

import {videosPlaceholder} from '../../assets'

const LikedVideosPage = () => {
    return (
        <Grid container spacing={ 4 } columns={ {xs: 12, sm: 3, md: 12} }>
            <Grid item xs={ 12 } sm={ 3 } md={ 12 } />

            <Grid item xs={ 1 } sm={ 1 } md={ 2 } />
            <Grid align='center' item xs={ 10 } sm={ 1 } md={ 8 }>
                <Typography component='h3' variant='h3' sx={ {fontWeight: 900} }>
                    These Are Your Liked Videos
                </Typography>
            </Grid>
            <Grid item xs={ 1 } sm={ 1 } md={ 2 } />

            <Grid item align='center' xs={ 12 } sm={ 8 } md={ 12 }>
                <Typography component='p' variant='body1'>
                    {"This page is still under construction. But here's a preview of what liked videos will look like"}
                </Typography>
            </Grid>

            <Grid item xs={ 12 }>
                <Image src={ videosPlaceholder } />
            </Grid>
        </Grid>
    )
}

export default LikedVideosPage
import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useLocation, useNavigate} from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import RootContext from '../../services/context-states/root-context';
import checkLicense from '../../helpers/license';

import * as permissionHelper from '../../helpers/permissions';
import {types} from '@apis';

const GlobalAlerts = ({isLoggedIn = false}) => {
  const {billingContext, messageContext, accessFlagsContext, userContext} = useContext(RootContext)
  const {billingInformation} = billingContext
  const {error, setError, successMessage, setSuccessMessage} = messageContext;
  const {currentUser} = userContext;
  const {accessFlags, setAccessFlags} = accessFlagsContext;

  const [hasLoaded, setHasLoaded] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const enableBilling = (userData) => {
      
    if(!userData || !userData.additionalData) {
        return false;
    }
    
    if(!userData.additionalData.parentAccount && userData.additionalData.userType === types.UserType.Individual) {
        return false;
    } 

    if(!permissionHelper.checkPermission(userData.additionalData.role.permissions || [], permissionHelper.permissions.ManageBilling)) {
        return false;
    }

    return true;
  }

  const enableManageUsers = (userData) => {
      
      if(!userData || !userData.additionalData) {
        return false;
      }

      if(!permissionHelper.checkPermission(userData.additionalData.role.permissions || [], permissionHelper.permissions.ManageUsers)) {
          return false;
      }

      return true;
  }

  // Global Error/Success Reset / Clearing
  useEffect(() => {
    setError('');
    setSuccessMessage('');
  }, [location]);

  useEffect(() => { 
    // Reset Has Loaded anytime Billing Information Changes
    if(hasLoaded) {
      setHasLoaded(false);
    }
  }, [billingInformation]);

  // Subscription Alert and Licensing alert
  useEffect(() => {

    if(hasLoaded) {
      return;
    }

    // Subscription Check
    // ******************************
    if (!currentUser || !billingInformation) {
      // Dont show if Null as its first load and unknown
      return;
    }

    if (Object.keys(billingInformation).length === 0) {
      setAccessFlags((prevState) => ({
        ...prevState,
        showIncompleteProfile: true,
        enableManageBilling: enableBilling(currentUser),
        enableManageUsers: enableManageUsers(currentUser)
      }));

      setHasLoaded(true);

      return;
    }

    if(!billingInformation.isActive) {
      setAccessFlags((prevState) => ({
        ...prevState,
        showIncompleteProfile: !billingInformation.isActive,
        enableManageBilling: enableBilling(currentUser),
        enableManageUsers: enableManageUsers(currentUser)
      }));

      setHasLoaded(true);

      return;
    }
    // **********************************

    // License Check
    // **********************************
    if (!billingInformation) {
      // Dont show if Null as its first load and unknown
      setAccessFlags((prevState) => ({
        ...prevState,
        showMissingLicense: false,
        enableManageBilling: enableBilling(currentUser),
        enableManageUsers: enableManageUsers(currentUser)
      }));

      setHasLoaded(true);

      return;
    }

    if (Object.keys(billingInformation).length === 0 || !billingInformation.data) {
      // Dont show as the billing banner would be showing
      setAccessFlags((prevState) => ({
        ...prevState, 
        showMissingLicense: false,
        enableManageBilling: enableBilling(currentUser),
        enableManageUsers: enableManageUsers(currentUser)
      }));

      setHasLoaded(true);

      return;
    }

    if (billingInformation.data.usedLicenses === 0 && Number(billingInformation.data.licenses) === 0) {
      // No used licenses, so no need to show
      setAccessFlags((prevState) => ({
        ...prevState,
        showMissingLicense: false,
        enableManageBilling: enableBilling(currentUser),
        enableManageUsers: enableManageUsers(currentUser)
      }));

      setHasLoaded(true);

      return;
    }

    setAccessFlags((prevState) => ({
      ...prevState, 
      hasLoaded: true,
      enableManageBilling: enableBilling(currentUser),
      enableManageUsers: enableManageUsers(currentUser),
      showMissingLicense: !checkLicense(currentUser.id, billingInformation.data.usedLicenses), 
      showUnusedLicenses: Number(billingInformation.data.licenses) > billingInformation.data.usedLicenses.length}));

    setHasLoaded(true);
    // **********************************
    
  }), [currentUser, billingInformation]
  
  return (
    <div id='private-global-alert' style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
      {(error.length > 0) && <Alert severity="error">{error}</Alert>}
      {(successMessage.length > 0) && <Alert severity="success">{successMessage}</Alert>}
      {isLoggedIn && (
        <>
          {accessFlags.showIncompleteProfile &&
            <Alert severity="warning" sx={{width: '25%'}}
              action={
                <Button color="inherit" size="small" onClick={() => navigate('/settings?show=Billing')}>
                  Update
                </Button>
              }>
              Incomplete Profile: Go to Settings to complete your profile
            </Alert>
          }
          {accessFlags.showMissingLicense &&
            <Alert severity="warning" sx={{width: '25%'}}>
              License: You do not have a license to access the video content
            </Alert>
          }
        </>
      )}
    </div>
  )
}

GlobalAlerts.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
}

export default GlobalAlerts
export {default as cluster1} from './1.0000.png'
export {default as cluster2} from './2.0000.png'
export {default as cluster3} from './3.0000.png'
export {default as cluster4} from './4.0000.png'
export {default as cluster5} from './5.0000.png'
export {default as cluster6} from './6.0000.png'
export {default as cluster7} from './7.0000.png'
export {default as cluster8} from './8.0000.png'
export {default as cluster9} from './9.0000.png'
export {default as cluster10} from './10.0000.png'
export {default as cluster11} from './11.0000.png'
export {default as cluster12} from './12.0000.png'
export {default as cluster13} from './13.0000.png'
export {default as cluster14} from './14.0000.png'
export {default as cluster15} from './15.0000.png'
export {default as cluster16} from './16.0000.png'
/* eslint-disable no-console */
import React, {useContext, useEffect, useState} from 'react';
import {Button, UserInvites} from '@elements';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import RootContext from '../../../services/context-states/root-context';
import {userApi} from '../../../api/openapi-axios-client';

const UserManagement = () => {
    const {billingContext, messageContext, userContext} = useContext(RootContext);
    const {billingInformation, setBillingInformation} = billingContext;
    const {error, setError, setSuccessMessage} = messageContext;
    const {currentUser} = userContext;
  
    // User Data
    const [loadedUsers, setLoadedUsers] = useState(false);
    const [currentUsers, setCurrentUsers] = useState(null);
  
    // License Data
    const [loadedLicenses, setLoadedLicenses] = useState(false);
    const [licenseData, setLicenseData] = useState(null);
  
    // Invite Dialog
    const [dialogOpen, setDialogOpen] = useState(false);

    const [userInvites, setUserInvites] = useState([]);
  
    useEffect(() => {
      const getData = async () => {
        if(loadedUsers && currentUsers !== null) { 
          return;
        }
    
        const combinedUsers = [];
    
        // Add Current User
        if(currentUser) {

          combinedUsers.push({
            id: currentUser.id,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            email: currentUser.email,
            status: currentUser.additionalData?.role?.name || 'Admin',
            licensed: isLicensed(currentUser.id)
          });
        }
    
        if(billingInformation && Object.keys(billingInformation).length > 0) {
          // Get Users
          let activeUsers = [];

          try {
            activeUsers = await userApi.user.childAccounts();
          }
          catch(error) {
            activeUsers = [];
          }
    
          activeUsers?.map((user) => {
            combinedUsers.push({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              status: user.additionalData?.role?.name || 'Active',
              licensed: isLicensed(user.id)
            });
          });
        }
  
        // Get Invites
        let invitedUsers = [];

        try {
          invitedUsers = await userApi.userInvites.list();
        }
        catch(error) {
          invitedUsers = [];
        }
        
        invitedUsers?.map((invite) => {
          combinedUsers.push({
            id: invite.id,
            firstName: '',
            lastName: '',
            email: invite.email,
            status: 'Invited',
            licensed: false
          });
        });
  
        setCurrentUsers(combinedUsers);
        setLoadedUsers(true);
      };
  
      getData().catch((error) => {
        console.log(error);
      });
  
      const licenseLoad = async () => {
  
        if(loadedLicenses) {
          return;
        }
  
        if(Object.keys(currentUser).length === 0) {
          return;
        }
  
        if(!billingInformation) {
          return;
        }
  
        if(Object.keys(billingInformation).length === 0) {
          setLicenseData({
            isActive: false,
            licenses: 0,
            usedLicenses: 0,
            remaining: 0,
            available: false,
          });
        }
  
        const licenseCheck = await userApi.billing.checkAvailableLicenses(billingInformation.id);
  
        setLicenseData(licenseCheck);
        setLoadedLicenses(true);
      };
  
      licenseLoad().catch((error) => {
        console.log(error);
      });
    }, [loadedUsers, loadedLicenses, billingInformation, currentUser]);  

    const isLicensed = id => {

      if(!billingInformation || Object.keys(billingInformation).length === 0) {
        return false;
      }

      return billingInformation?.data?.usedLicenses.length > 0 ? billingInformation?.data?.usedLicenses.includes(id) : false

    }
  
    const deleteInvite = (id) => {
      setError('');
      userApi.userInvites.deleteInvite(id).catch((error) => {
        setError(error.message);
      });
  
      setLoadedUsers(false);
    }

    const refreshInvite = (id) => {
      setError('');
      userApi.userInvites.refresh(id).then(() => {
        setSuccessMessage('Invite Refreshed');
      }).catch((error) => {
        setError(error.message);
      });
  
      setLoadedUsers(false);
    }
  
    const addLicense = async (id) => {
      setError('');
      const response = await userApi.billing.assignLicense(id)
      .catch((error) => {
        setError(error.message);
        return null;
      });
      
      if(response) {
        const updatedBilling = await userApi.billing.checkBilling();
  
        if(updatedBilling) {
          setBillingInformation(updatedBilling);
          setLoadedUsers(false);
          setLoadedLicenses(false);
        }
      }
    }
  
    const removeLicense = async (id) => {
      setError('');
      const response = await userApi.billing.unassignLicense(id)
      
      if(response) {
        const billingUpdate = await userApi.billing.checkBilling();
  
        if(billingUpdate) {
          setBillingInformation(billingUpdate);
          setLoadedUsers(false);
          setLoadedLicenses(false);
        }
      }
    }
  
    const getLicenseComponent = (row) => {
  
      const notAvailable = (
        <Typography>
          None Available
        </Typography>
      );
  
      const addButton = (
        <Button format='primary' text='Assign' style={{width: 50, fontSize: 9}} onClick={async () => await addLicense(row.id)} />
      );
  
      const removeButton = (
        <Button format='primary' text='Unassign' style={{width: 50, fontSize: 9}} onClick={async () => await removeLicense(row.id)} />
      );
  
      if(!licenseData || !row) {
        return notAvailable;
      }
  
      // Check if Row is still an Invite
      if(row.status === 'Invited') {
        return (<></>);
      }

      // Licenses are available, but none are assigned to this row, add button to assign
      if(licenseData?.isActive && licenseData?.available && !row.licensed) {
        return addButton;
      }
  
      // License is assigned to this row, add button to remove
      if(row.licensed) {
        return removeButton;
      }
  
      return notAvailable;
    }

    const getRowButtons = (row) => { 
      return (
        <Stack direction='row' spacing={0}>
          {getUserRefreshComponent(row)}
          {getUserDeleteComponent(row)}
        </Stack>
      )
    }
  
    const getUserDeleteComponent = (row) => {
      return (
        <IconButton disabled={ row.status !== 'Invited' } onClick={() => deleteInvite(row.id)}>
          <DeleteIcon color={ row.status === 'Invited' ? 'primary' : '' } />
        </IconButton>
      );
    }

    const getUserRefreshComponent = (row) => {
      return (
        <IconButton disabled={ row.status !== 'Invited' } onClick={() => refreshInvite(row.id)}>
          <AutorenewIcon color={ row.status === 'Invited' ? 'primary' : '' } />
        </IconButton>
      );
    }

    const handleInviteUsers = () => {
      setError('');

      userInvites.forEach((invite) => {

          const {email, roleId} = invite;

          userApi.userInvites.createInvite({email, roleId}).then(() => {
          }).catch((error) => {
              setError(error.message);
          });
      });

      if(error === '') {
          setSuccessMessage('Invites Sent');
          setLoadedUsers(false);
          setDialogOpen(false);
          setUserInvites([]);
      }
  }
  
    return (
      <Grid container spacing={2}>
        <Dialog open={dialogOpen} PaperProps={{sx: {width: '60%', height: '40%'}}}>
          <DialogTitle>
            Invite Users
            <IconButton aria-label="close" onClick={() => setDialogOpen(false)} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <div style={{padding: 15, display:'flex', flexDirection:'column', height:'100%'}}>
            <div style={{flexGrow:1, marginBottom:2}}>
              <UserInvites userInvites={userInvites} setUserInvites={setUserInvites} />
            </div>
            <Button format='primary' text='Send Invite(s)' style={{width: 150}} onClick={() => handleInviteUsers()} />
          </div>
        </Dialog>
        <Grid container align='left' style={{marginTop: 20}} spacing={4}>
          <Grid item xs={4}>
            <Stack direction='row' spacing={2}>
              {/*
              UNCOMMENT THIS WHEN NEEDING TO ADD ACTUAL FILTERING
              <Input id='txtSeachField' style={{width: 250}}
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                }/>
              */}
              <Button format='primary' text='+ Invite Users' style={{width: 150}} onClick={() => setDialogOpen(true)} />
            </Stack>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={5}>
            <Paper style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', verticalAlign: 'middle', padding: '5px'}}>
              <Stack direction='row' spacing={2}>
                <Typography variant='caption' fontWeight={'bold'}>
                  Licenses
                </Typography>
                <Typography variant='caption'>
                  Purchased: {licenseData && licenseData.licenses ? licenseData.licenses : '0'}
                </Typography>
                <Typography variant='caption'>
                  Assigned: {licenseData && licenseData.usedLicenses ? licenseData.usedLicenses : '0'}
                </Typography>
                <Typography variant='caption'>
                  Available: { licenseData && licenseData.remaining ? licenseData.remaining : '0'}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size='small' aria-label='Users & Invites'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align='center'>Email</TableCell>
                  <TableCell align='center'>Role/Status</TableCell>
                  <TableCell align='center'>License</TableCell>
                  <TableCell align='center' />
                </TableRow>
              </TableHead>
              <TableBody>
                {currentUsers?.map((row) => (
                  <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell component='th' scope='row'>{row.firstName} {row.lastName}</TableCell>
                    <TableCell align='center'>{row.email}</TableCell>
                    <TableCell align='center'>{row.status}</TableCell>
                    <TableCell align='center'>{ getLicenseComponent(row) }</TableCell>
                    <TableCell align='center'>{ getRowButtons(row) }</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    )
}

export default UserManagement
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {assessmentApi} from '@apis';
import {cluster1, cluster2, cluster3, cluster4, cluster5, cluster6, cluster7, cluster8, cluster9, cluster10, cluster11, cluster12, cluster13, cluster14, cluster15, cluster16} from '../../assets';

const LibraryClustersPage = () => {

  const [careerClusters, setCareerClusters] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    
    const getClusters = async () => {
      const response = await assessmentApi.careerData.getONetCareerClusters();

      if(response && response.length) {
        setCareerClusters(response);
      }
    }
    
    if(!careerClusters.length) {
      // fetch career clusters
      getClusters();
    }
  }, [])

  const getClusterImage = (clusterCode) => {
    switch(clusterCode) {
      case '1.0000':
        return cluster1;
      case '2.0000':
        return cluster2;
      case '3.0000':
        return cluster3;
      case '4.0000':
        return cluster4;
      case '5.0000':
        return cluster5;
      case '6.0000':
        return cluster6;
      case '7.0000':
        return cluster7;
      case '8.0000':
        return cluster8;
      case '9.0000':
        return cluster9;
      case '10.0000':
        return cluster10;
      case '11.0000':
        return cluster11;
      case '12.0000':
        return cluster12;
      case '13.0000':
        return cluster13;
      case '14.0000':
        return cluster14;
      case '15.0000':
        return cluster15;
      case '16.0000':
        return cluster16;
      default:
        return null;
    }
  }

  return (
    <Box>
      <Typography sx={{fontWeight: 900, fontSize:'24px', marginBottom: '20px'}}>
        Career Clusters
      </Typography>
      <Box sx={{
        display:'flex', 
        flexDirection:'row', 
        flexWrap:'wrap', 
        justifyContent: 
        'space-between', 
        gap:'10px', 
        minWidth:1140, 
        maxWidth:1140}}>
        {careerClusters.length && careerClusters.sort((a,b) => a.code - b.code).map(cluster => (
          <Box key={cluster.id} sx={{minWidth:270, maxWidth:270, cursor:'pointer'}} onClick={()=> navigate(`/library/cluster/${cluster.id}`)}>
            <img src={getClusterImage(cluster.code)} alt={cluster.title} />
            <Typography sx={{fontWeight: 900, fontSize:'18px'}}>
              {cluster.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default LibraryClustersPage
/* eslint-disable react/prop-types */
import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import BackArrow from '@mui/icons-material/ArrowBack';
import {BarChart, BarLabel} from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import {assessmentApi, videoApi} from '@apis';
import {HorizontalScrollBox, Loading, Modal} from '@elements';

import CloseIcon from '@mui/icons-material/Close';

import VideoPlayer from '../videos/VideoPlayer';
import {returnSalaryData} from '../../helpers/results';
import {truncateString} from '../../helpers/strings';

import RootContext from '../../services/context-states/root-context';

const pageStyles = {
    infoDiv: {
        display:'flex',
        flexDirection:'column',
        gap: 4,
        marginTop: 15
    },
    headerStyle: {
        fontWeight: 900,
        fontSize: 18,
        color: 'grey.500'
    },
    detailStyle: {
        fontSize: 14,
        color: 'black'
    }
};

const tabsStyle = {
    minWidth: 195,
    width: 195,
    border: 1,
    borderTopLeftRadius:'10px',
    borderBottomLeftRadius: '10px',
    borderColor: '#D9D9D9',
    background: '#F5F6F7'
}

const tabStyle = {
    alignItems: 'start',
    fontSize: 14,
    color: 'black',
    textTransform: 'none', 
}

const tabSectionHeaders = [
    'Knowledge is the understanding you have about a given topic, gained through learning, experience, or observation. Expertise in these critical domains is vital for success in this profession:',
    'These basic skills are developed capacities that facilitate learning or the more rapid acquisition of knowledge:',
    'Technological skills are the application of machines or technological systems technologies to design, set-up, operate, and correct malfunctions:',
    'Abilities are enduring attributes of the individual that influence performance:',
    'Work activities are general types of job behaviors occurring on multiple jobs:',
    'These are the day-to-day tasks that are expected in this role:',
    'Work styles are personal characteristics that can affect how well someone performs a job. These are the work styles that are critical to a person’s success in this occupation:',
    'Work values are global aspects of work that are important to a person’s satisfaction.  These are the work values important to this career:'
]

const baseSalaryTableData = {
    title: 'Annual Salary',
    location: 'National',
    salaries: [
        {
            key: 'ninetiethPercentile',
            title: 'Top Earners',
            hourly: 'N/A',
            annual: 'N/A'
        },
        {
            key: 'seventyFifthPercentile',
            title: '75th Percentile',
            hourly: 'N/A',
            annual: 'N/A'
        },
        {
            key: 'average',
            title: 'Average',
            hourly: 'N/A',
            annual: 'N/A'
        },
        {
            key: 'twentyFifthPercentile',
            title: '25th Percentile',
            hourly: 'N/A',
            annual: 'N/A'
        },
        {
            key: 'tenthPercentile',
            title: '10th Percentile',
            hourly: 'N/A',
            annual: 'N/A'
        }
    ]
}

const baseSalaryChartData = {
    annual: {
        values: [10, 25, 50, 25, 10],
        labels: ['', ' ', 'Nation Average: $0', '  ', '    '],
        colors: ['#A8B2FF', '#A8B2FF', '#2B3EAA', '#A8B2FF', '#A8B2FF']
    },
    hourly: {
        values: [10, 25, 50, 25, 10],
        labels: ['', ' ', 'National Average: $0', '  ', '    '],
        colors: ['#A8B2FF', '#A8B2FF', '#2B3EAA', '#A8B2FF', '#A8B2FF']
    }
}

const CareerDetailsPage = () => {

    const {messageContext} = useContext(RootContext);
    const {setError} = messageContext;

    const [isLoading, setIsLoading] = useState(true);
    const [displayVideoModal, setDisplayVideoModal] = useState(false);
    const [relatedVideos, setRelatedVideos] = useState(null);
    const [videoWatch, setVideoWatch] = useState(null);

    const [category, setCategory] = useState('');
    const [occupation, setOccupation] = useState({});

    const [salaryTypeToShow, setSalaryTypeToShow] = useState('annual');
    const [salaryData, setSalaryData] = useState([]);

    const [salaryStateFliters, setSalaryStateFilters] = useState([]);
    const [selectedSalaryStateFilter, setSelectedSalaryStateFilter] = useState('national');

    const [salaryCityFilters, setSalaryCityFilters] = useState([]);
    const [selectedSalaryCityFilter, setSelectedSalaryCityFilter] = useState('all');
    
    const [salaryTableData, setSalaryTableData] = useState(baseSalaryTableData);
    const [salaryChartData, setSalaryChartData] = useState([]);

    const [tabValue, setTabValue] = useState(0);

    const navigate = useNavigate();

    const {state} = useLocation();
    const {userId, careerClusterId, detailId} = useParams()
    const {careerData} = state || {};

    const CustomTabPanel = (props) => {
        const {children, value, index, ...other} = props;
      
        return (
            <>
                {value === index && (
                    <div
                        role="tabpanel"
                        hidden={value !== index}
                        id={`vertical-tabpanel-${index}`}
                        aria-labelledby={`vertical-tab-${index}`}
                        {...other}
                        style={{display:'flex',
                                width: '100%',
                                minHeight:675,
                                flexGrow: 1,
                                gap: 5,
                                borderStyle: 'solid',
                                borderLeftWidth: 0,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderTopRightRadius: '10px',
                                borderBottomRightRadius: '10px',
                                borderColor: '#D9D9D9',
                                padding: 15,
                                background:'#FFFFFF'
                        }}>
                        {children}
                </div>
                )}
            </>
        );
    }

    const numberFormatter = (value, decimal) => {
        return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString('en-US', {useGrouping: true});
    };

    const shortenSalary = (value) => {
        if(!value) {
            return 'N/A';
        }

        if(value.length === 3) {
            return value;
        }
        else if(value.length === 4) {
            return `${value[0]}.${value[1]}K`;
        }
        else if(value.length === 5) {
            return `${value.substring(0, 2)}.${value[2]}K`;
        }
        else if(value.length === 6) {
            return `${value.substring(0, 3)}.${value[3]}K`;
        }
        else {
            return value;
        }
    }

    const handleStateSalaryFilterChange = (e) => {
        setSelectedSalaryStateFilter(e.target.value);
        setSelectedSalaryCityFilter('all');

        if(e.target.value === 'national') {
            setSalaryCityFilters([]);
            generateSalaryData('national', null);
        }
        else {
            const selectedState = [
                {
                    value: 'all',
                    display: 'All Cities',
                    salaryData: returnSalaryData(salaryData.filter(item => item.areaType === 2 && item.primState === e.target.value))
                }
            ]
            
            salaryData.filter(item => item.areaType === 4 && item.primState === e.target.value).forEach((item) => {
                selectedState.push({
                    value: item.id,
                    display: item.areaTitle.replace(`, ${item.primState}`, ''),
                    salaryData: returnSalaryData(item)
                });
            });

            setSalaryCityFilters(selectedState);
            generateSalaryData(e.target.value, 'all');
        }
    }

    const handleCitySalaryFilterChange = (e) => {
        setSelectedSalaryCityFilter(e.target.value);
        generateSalaryData(selectedSalaryStateFilter, e.target.value);
    }

    const generateSalaryData = (stateFilter, cityFilter) => {

        const title = salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary';

        // Is the current main filter set to national
        if(stateFilter === 'national') {

            const salaries = salaryStateFliters.find(item => item.value === 'national').salaryData

            setSalaryTableData({
                title: title,
                location: 'National',
                salaries: salaries
            });

            generateSalaryChartData(title, 'National', salaries);
        }
        else if (stateFilter !== 'national' && cityFilter === 'all') {

            const selectedState = salaryStateFliters.find(item => item.value === stateFilter);
            const salaries = salaryStateFliters.find(item => item.value === selectedState.value).salaryData

            setSalaryTableData({
                title: title,
                location: selectedState.display,
                salaries: salaries
            });

            generateSalaryChartData(title, selectedState.display, salaries);
        }
        else if (cityFilter !== 'all') {
            const selectedCity = salaryCityFilters.find(item => item.value === cityFilter);

            setSalaryTableData({
                title: title,
                location: selectedCity.display,
                salaries: selectedCity.salaryData
            });

            generateSalaryChartData(title, selectedCity.display, selectedCity.salaryData);
        }
        else {
            setSalaryTableData(baseSalaryTableData);
            setSalaryChartData(baseSalaryChartData);
        }

    }

    const generateSalaryChartData = (title, location, data) => {

        let dataToUse = data;

        if(data.length !== 5) {
            dataToUse = [
                {
                    key: 'tenthPercentile',
                    title: '10th Percentile',
                    annual: data.find(item => item.key === 'tenthPercentile').annual,
                    hourly: data.find(item => item.key === 'tenthPercentile').hourly
                },
                {
                    key: 'twentyFifthPercentile',
                    title: '',
                    annual: data.find(item => item.key === 'tenthPercentile').annual + 100,
                    hourly: data.find(item => item.key === 'tenthPercentile').hourly + 1
                },
                {
                    key: 'average',
                    title: 'Average',
                    annual: data.find(item => item.key === 'average').annual,
                    hourly: data.find(item => item.key === 'average').hourly
                },
                {
                    key: 'seventyFifthPercentile',
                    title: '',
                    annual: data.find(item => item.key === 'ninetiethPercentile').annual - 100,
                    hourly: data.find(item => item.key === 'ninetiethPercentile').hourly - 1
                },
                {
                    key: 'ninetiethPercentile',
                    title: '90th Percentile',
                    annual: data.find(item => item.key === 'ninetiethPercentile').annual,
                    hourly: data.find(item => item.key === 'ninetiethPercentile').hourly
                }
            ]
        }

        const chartData = {
            title: title,
            location: location,
            annual: {
                values: [10, 25, 50, 25, 10],
                labels: dataToUse.sort((a, b) => a.annual - b.annual).map((item, index) => {
                    if(item.key === 'average') {
                        return `${location.substring(0, 10)} Average: $${shortenSalary(item.annual.toString())}`;
                    }
                    else if(item.key === 'tenthPercentile' || item.key === 'ninetiethPercentile') {
                        return `$${shortenSalary(item.annual.toString())}`;
                    }
                    else {
                        return ' '.repeat(index);
                    }
                }),
                colors: dataToUse.map((item) => item.key === 'average' ? '#2B3EAA' : '#A8B2FF')
            },
            hourly: {
                values: [10, 25, 50, 25, 10],
                labels: dataToUse.sort((a, b) => a.annual - b.annual).map((item, index) => {
                    if(item.key === 'average') {
                        return `${location.substring(0, 10)} Average: $${shortenSalary(item.hourly.toString())}`;
                    }
                    else if(item.key === 'tenthPercentile' || item.key === 'ninetiethPercentile') {
                        return `$${shortenSalary(item.annual.toString())}`;
                    }
                    else {
                        return ' '.repeat(index);
                    }
                }),
                colors: dataToUse.map((item) => item.key === 'average' ? '#2B3EAA' : '#A8B2FF')
            }
        }

        setSalaryChartData(chartData);
    }

    const generateRelatedVideoCard = (video, i) => {
        return (
            <Box key={i} sx={{
                display:'flex',
                flexDirection:'column',
                cursor:'pointer',
                background:'white',
                alignContent:'center',
                justifyContent:'center',
                justifyItems:'center',
                margin: 1}} onClick={() => {setVideoWatch(video);setDisplayVideoModal(true)}}>
                <img src={video.thumbnail} style={{width: '250px', height: '150px'}} />
                <Typography sx={{fontSize: 12, fontWeight: 'bold', color: 'black'}}>
                    {truncateString(video.title, 50)}
                </Typography>
            </Box>
        )
    }

    const generateEductionItem = (data) => {
        return (
            <div style={{display:'flex', flexDirection:'row', gap: 10, alignItems: 'center'}}>
                <Typography sx={{fontSize: 14, color: '#3347E6', fontWeight: 900}}>
                    {`${data?.score?.value || 0}%`}
                </Typography>
                <LinearProgress variant="determinate" value={data?.score?.value || 0} sx={{height: 10, width: 100, borderRadius: 2}} />
                <Typography sx={{fontSize: 14, color: 'black'}}>
                    {`${data.name} required`}
                </Typography>
            </div>
        )
    }

    const generateTabProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
          };
    }

    const generateTabComponents = (index, data) => {
        return (
            <div style={{display:'flex', flexDirection:'column', gap: 0}}>
                <Typography sx={{...pageStyles.detailStyle}}>
                    {tabSectionHeaders[index]}
                </Typography>

                <ul>
                    {data && data.map((element, i) => {
                        if(element.name && element.description) {
                            return (
                                <li key={i} style={{marginTop: 15}}>
                                    <Typography key={i} sx={{...pageStyles.detailStyle}}>
                                        <b>{element.name} — </b> {element.description}
                                    </Typography>
                                </li>
                            )
                        }

                        if(element.title?.name && element.example.length) {
                            return (
                                <li key={i} style={{marginTop: 15}}>
                                    <Typography key={i} sx={{...pageStyles.detailStyle}}>
                                        <b>{element.title.name} — </b> {element.example.map((example) => example.name).join(', ')}
                                    </Typography>
                                </li>
                            )
                        }

                        if(element.name) {
                            return (
                                <li key={i} style={{marginTop: 15}}>
                                    <Typography key={i} sx={{...pageStyles.detailStyle}}>
                                        {element.name}
                                    </Typography>
                                </li>
                            )
                        }

                        if(element.statement) {
                            return (
                                <li key={i} style={{marginTop: 15}}>
                                    <Typography key={i} sx={{...pageStyles.detailStyle}}>
                                        {element.statement}
                                    </Typography>
                                </li>
                            )
                        }

                    })}
                </ul>
            </div>
        )
    }

    const generateWorkContextItem = (item, index) => {

        const percentage = item?.response?.length ? item.response[0]?.percentage : 0;
        const name = item?.response?.length ? item.response[0]?.name : ' No Response';

        return (
            <div style={{display:'flex', flexDirection:'row', gap: 10, alignItems: 'center'}}>
                <LinearProgress variant="determinate" value={percentage} sx={{height: 10, width: 80, borderRadius: 2}} />
                <Typography sx={{fontSize: 14, color: 'black'}}>
                    {`${index}. ${item.name} — `} <b style={{color: '#3347E6'}}>{`${percentage}% responded "${name}"`}</b>
                </Typography>
            </div>
        )
    }

    useEffect(() => {

        const getData = async () => {

            let displayData;

            if(careerData) {
                
                displayData = {
                    category: careerData.category,
                    occupation: {
                        ...careerData.occupation,
                        ...careerData.occupation.details
                    }
                }
    
                setCategory(displayData.category);
                setOccupation(displayData.occupation);
            }
            else {
                // Get the Cluster / Occupation / Career data
                const cluster = await assessmentApi.careerData.getCareerCluster(careerClusterId);

                if(!cluster) {
                    setError('Unable to load career details');
                    setIsLoading(false);
                    return;
                }

                const clusterData = await assessmentApi.careerData.getOccupationDetails(careerClusterId, detailId);

                if(!clusterData) {
                    setError('Unable to load career details');
                    setIsLoading(false);
                    return;
                }
                
                const careerDetails = await assessmentApi.careerData.getONetCareerData(clusterData.occupationCode);

                if(!careerDetails) {
                    setError('Unable to load career details');
                    setIsLoading(false);
                    return;
                }

                if(clusterData.occupationAlternateTitle) {
                    careerDetails.hideAlternateTiltles = true;
                    careerDetails.title = clusterData.occupationAlternateTitle;
                    careerDetails.backUrl = `/library/cluster/${careerClusterId}`;
                }
                else {
                    careerDetails.backUrl = `/careers/details/${userId}/${careerClusterId}`
                }

                displayData = {
                    category: cluster.title,
                    occupation: careerDetails
                }

                setCategory(displayData.category);
                setOccupation(displayData.occupation);
            }

            if(!relatedVideos && displayData?.occupation?.code) {
                const related = await videoApi.videos.getRelatedVideos(displayData?.occupation?.code, 4);

                if(related) {
                    setRelatedVideos(related);
                }
                else {
                    setRelatedVideos([]);
                }
            }

            if((!salaryData || !salaryData.length) && displayData?.occupation?.code) {
                const blsData = await assessmentApi.careerData.getBlsWageData(displayData?.occupation?.code);

                if(blsData && blsData.length) {
                    setSalaryData(blsData);

                    const convertedNationalStateData = blsData.sort((a, b) => a.areaType - b.areaType).filter(item => item.area <= 99 && item.area !== 66 && item.area !== 72).map((item) => {
                        return {
                            value: item.areaType === 1 ? 'national' : item.primState,
                            display: item.areaType === 1 ? 'National' : item.areaTitle,
                            salaryData: returnSalaryData(item)
                        }
                    });

                    setSalaryStateFilters(convertedNationalStateData);

                    setSalaryTableData({
                        title: salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary',
                        location: 'National',
                        salaries: convertedNationalStateData.find(item => item.value === 'national').salaryData
                    });

                    generateSalaryChartData(salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary', 'National', convertedNationalStateData.find(item => item.value === 'national').salaryData);
                }
                else {
                    if(displayData.occupation?.jobOutlook?.salary) {
                        const oNetSalaryData = returnSalaryData(displayData.occupation.jobOutlook.salary);

                        setSalaryData(oNetSalaryData);

                        setSalaryTableData({
                            title: salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary',
                            location: 'National',
                            salaries: oNetSalaryData
                        });

                        generateSalaryChartData(salaryTypeToShow === 'annual' ? 'Annual Salary' : 'Hourly Salary', 'National', oNetSalaryData);
                    }
                }
            }
        }
        
        try {
            getData();
        }
        finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <>
        <Modal
            open={displayVideoModal}
            onClose={() => setDisplayVideoModal(false)}
            modalTitle='Watch Video'
            modalDescription='Watch Video'
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 700,
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 5,
                p: 4,
            }}>
                <Stack spacing={2}>
                    <IconButton aria-label="close" onClick={() => setDisplayVideoModal(false)} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                        <CloseIcon />
                    </IconButton>
                    {videoWatch?.id && <VideoPlayer video={videoWatch} autoplay={1} />}
                </Stack>
        </Modal>
        {(isLoading && <Loading />) ||
            <div style={{width:'100%', height:'100%', gap: 2}}>
                {/* NAV HEADER */}
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', gap: 1.5}}>
                    <IconButton onClick={() => navigate(occupation?.backUrl)}>
                        <BackArrow />
                    </IconButton>
                    <Typography sx={{fontSize: 20, color: 'grey.500', verticalAlign:'center', cursor:'pointer'}} onClick={() => navigate(occupation?.backUrl)}>
                        {`Return to ${category}`}
                    </Typography>
                </div>
                {/* NAV HEADER */}
                <Box padding={4} sx={{display:'flex', flexDirection:'column', borderRadius: 2, border: 1, borderColor: 'grey.500', bgcolor: 'white', marginTop: 2}}>
                    
                    {/* OCCUPATION HEADER */}
                    <div style={{display:'flex', flexDirection:'row', gap: 25, alignItems: 'center'}}>
                        <Typography sx={{fontSize: 20, fontWeight: 900, color: 'black'}}>
                            {occupation?.title}
                        </Typography>
                        {occupation?.tags?.bright_outlook && (
                                    <Typography sx={{fontSize: 14, color: 'red'}}>
                                        {`${String.fromCodePoint('0x1F680')} Fast-growing career`}
                                    </Typography>)
                        }
                    </div>
                    {/* OCCUPATION HEADER */}

                    {/* OCCUPATION OVERVIEW */}
                    <div style={pageStyles.infoDiv}>
                        <Typography sx={pageStyles.headerStyle}>
                            Overview
                        </Typography>
                        <Typography sx={pageStyles.detailStyle}>
                            {occupation?.description}
                        </Typography>
                        {!occupation?.hideAlternateTiltles && (
                            <Typography sx={{...pageStyles.detailStyle, marginTop: 2}}>
                                <b>Sample of reported job titles:</b> {occupation?.alternateTitles?.join(', ') || ''}
                            </Typography>
                        )}
                    </div>
                    {/* OCCUPATION OVERVIEW */}

                    {/* RELATED VIDEOS */}
                    <div style={pageStyles.infoDiv}>
                        <Typography sx={pageStyles.headerStyle}>
                            Related Videos
                        </Typography>
                        <HorizontalScrollBox toRender={relatedVideos && relatedVideos.length ? relatedVideos.map((related, i) => generateRelatedVideoCard(related, i)) : []} />
                    </div>
                    {/* RELATED VIDEOS */}
                    
                    {/* GENERAL */}
                    <div style={{...pageStyles.infoDiv, gap: 15}}>
                        <Typography sx={pageStyles.headerStyle}>
                            General
                        </Typography>
                        {/* EDUCATION */}
                        <div style={{display:'flex', flexDirection:'column', gap: 5}}>
                            <Typography sx={{...pageStyles.detailStyle, fontWeight: 900}}>
                                Education
                            </Typography>
                            <Typography sx={pageStyles.detailStyle}>
                                How much education does a new hire need to perform a job in this occupation? Respondents said:
                            </Typography>
                            <div style={{display:'flex', flexDirection:'column', gap: 5, marginTop: 10}}>
                                {occupation?.education?.level_required?.category?.sort((a, b) => b.score?.value - a.score?.value).map((level) => generateEductionItem(level))}
                            </div>
                        </div>
                        {/* EDUCATION */}
                        {/* WAGE */}
                        <div style={{display:'flex', flexDirection:'column', gap: 15, marginTop: 10}}>
                            <Typography sx={{...pageStyles.detailStyle, fontWeight: 900}}>
                                Wage & Employment Trends
                            </Typography>
                                {/* FILTERS */}
                                <div style={{display:'flex', flexDirection:'row', gap: 15, alignItems: 'center'}}>
                                    {salaryStateFliters.length > 0 && (
                                    <>
                                    <Select sx={{fontSize:14, width: 200, fontWeight:900}} variant='standard' value={selectedSalaryStateFilter} onChange={(e) => handleStateSalaryFilterChange(e)} displayEmpty size={'small'}>
                                        {salaryStateFliters.map((item, index) => <MenuItem key={index} value={item.value}>{item.display}</MenuItem>)}
                                    </Select>
                                    <Select disabled={selectedSalaryStateFilter === 'national'} sx={{fontSize:14, width: 500, fontWeight:900}} variant='standard' value={selectedSalaryCityFilter} onChange={(e) => handleCitySalaryFilterChange(e)} displayEmpty size={'small'}>
                                        {salaryCityFilters.map((item, index) => <MenuItem key={index} value={item.value}>{item.display}</MenuItem>)}
                                    </Select>
                                    </>
                                    )}
                                    <Select sx={{fontSize:14, width:125, fontWeight:900}} variant='standard' value={salaryTypeToShow} onChange={(e) => setSalaryTypeToShow(e.target.value)} displayEmpty size={'small'}>
                                        <MenuItem value={'annual'}>Annual Salary</MenuItem>
                                        <MenuItem value={'hourly'}>Hourly Salary</MenuItem>
                                    </Select>
                                </div>
                                {/* FILTERS */}
                            <div style={{display:'flex', flexDirection:'row', gap: 5, width:'100%', flexGrow: 1}}>
                                {/* CHART VIEW */}
                                <div style={{display:'flex', flexDirection:'column', gap: 5, flexGrow: 1}}>
                                    <Typography sx={{fontSize: 14, color: '#797777'}}>
                                    {salaryChartData.location} wage:
                                    </Typography>
                                    {salaryData && salaryData.length > 0 &&
                                    (
                                        <div>
                                            <BarChart
                                            height={300}
                                            borderRadius={5}
                                            leftAxis={null}
                                            bottomAxis={{
                                                disableLine: true,
                                                disableTicks: true
                                            }}
                                            tooltip={{trigger: 'none'}}
                                            axisHighlight={{
                                                x: 'none',
                                                y: 'none'
                                            }}
                                            barLabel={(item, context) => {
                                                return (
                                                    <BarLabel {...item} {...context} />
                                                )
                                            }}
                                            series={[
                                            {data: salaryChartData[salaryTypeToShow].values, id: 'pvId', hideTooltip: true},
                                            ]}
                                            xAxis={[{data: salaryChartData[salaryTypeToShow].labels, scaleType: 'band', labelColor: '#797777', hideTooltip: true,
                                                colorMap: {
                                                    type: 'ordinal',
                                                    colors: salaryChartData[salaryTypeToShow].colors
                                                }
                                            }]}/>
                                        </div>
                                    )}
                                </div>
                                {/* CHART VIEW */}
                                {/* TABLE VIEW */}
                                <div>
                                    <Typography sx={{fontSize: 14, color: '#797777'}}>
                                        Table view:
                                    </Typography>
                                    <TableContainer sx={{borderRadius: '10px', border: 1, borderColor: '#D9D9D9'}}>
                                        <Table size='small' sx={{borderCollapse: 'collapse', borderStyle: 'hidden', '& td': {border: 1, borderColor:'#D9D9D9'}}}>
                                            <TableHead sx={{background: '#D9D9D9'}}>
                                                <TableRow>
                                                    <TableCell sx={{width: 175}}>
                                                        <Typography sx={{fontSize: 14, color: '#979797'}}>
                                                            {salaryTableData.title}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{width: 125}}>
                                                        <Typography sx={{fontSize: 14}}>
                                                            {salaryTableData.location.substring(0, 8)}{salaryTableData.location.length > 8 ? '...' : ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {salaryTableData.salaries.sort((a, b) => b.annual - a.annual).map((item, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <Typography sx={{fontSize: 14}}>
                                                                    {item.title}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography sx={{fontSize: 14}}>
                                                                    {item[salaryTypeToShow] && item[salaryTypeToShow] !== 'N/A' ? `$${numberFormatter(item[salaryTypeToShow], 2)}` : 'N/A'}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                {/* TABLE VIEW */}
                            </div>
                        </div>
                        {/* WAGE */}
                    </div>
                    {/* GENERAL */}
                    
                    {/* KEY CHARACTERISTICS */}
                    <div style={{...pageStyles.infoDiv, gap: 15, width:'100%'}}>
                        <Typography sx={pageStyles.headerStyle}>
                            Key Characteristics
                        </Typography>
                        {/* TABS */}
                        <div style={{display:'flex', flexDirection:'row', gap:0, width:'100%', flexGrow: 1}}>
                            <Tabs orientation="vertical" 
                                variant="scrollable" 
                                aria-label="Key Characteristics Tabs" 
                                value={tabValue} 
                                onChange={(event, index) => setTabValue(index)} 
                                sx={tabsStyle}>
                                <Tab label="Knowledge" {...generateTabProps(0)} sx={tabStyle}/>
                                <Tab label="Skills" {...generateTabProps(1)} sx={tabStyle}/>
                                <Tab label="Technology Skills" {...generateTabProps(2)} sx={tabStyle}/>
                                <Tab label="Abilities" {...generateTabProps(3)} sx={tabStyle}/>
                                <Tab label="Detailed Work Activities" {...generateTabProps(4)} sx={tabStyle}/>
                                <Tab label="Tasks" {...generateTabProps(5)} sx={tabStyle}/>
                                <Tab label="Work Styles" {...generateTabProps(6)} sx={tabStyle}/>
                                <Tab label="Work Values" {...generateTabProps(7)} sx={tabStyle}/>
                            </Tabs>
                            <CustomTabPanel value={tabValue} index={0}>
                                {generateTabComponents(0, occupation?.knowledge?.element)}
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={1}>
                                {generateTabComponents(1, occupation?.skills?.element)}
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={2}>
                                {generateTabComponents(2, occupation?.technologySkills?.category)}
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={3}>
                                {generateTabComponents(3, occupation?.abilities?.element)}
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={4}>
                                {generateTabComponents(4, occupation?.detailedWorkActivities?.activity)}
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={5}>
                                {generateTabComponents(5, occupation?.tasks?.task)}
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={6}>
                                {generateTabComponents(6, occupation?.workStyles?.element)}
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={7}>
                                {generateTabComponents(7, occupation?.workValues?.element)}
                            </CustomTabPanel>
                        </div>
                        {/* TABS */}
                        <Typography sx={{...pageStyles.detailStyle, fontWeight: 900, marginTop: 2}}>
                                Work Context
                        </Typography>
                        <Typography sx={{...pageStyles.detailStyle}}>
                            Hear what others have to say about what their day-to-day look like:
                        </Typography>
                        <div style={{display:'flex', flexDirection:'column', gap: 10, marginTop: 10}}>
                            {occupation?.workContext?.element?.slice(0, occupation?.workContext?.element.length > 5 ? 5 : occupation?.workContext?.element.length).map((item, index) => generateWorkContextItem(item, index + 1))}
                        </div>
                    </div>
                    {/* KEY CHARACTERISTICS */}
                </Box>
            </div>
        }
        </>
    )
}

export default CareerDetailsPage
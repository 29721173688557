import React, {useState} from 'react';

import Drawer from '@mui/material/Drawer';
import DrawerContents from './SideAppBarContents/DrawerContents';

const SideAppBar = () => {
    const [open, setOpen] = useState(true);

    return (
        <div>
            <Drawer
                anchor="left"
                open={ open }
                variant="persistent"
            >
                <DrawerContents toggleDrawer={ setOpen } />
            </Drawer>
        </div>
    )
}

SideAppBar.propTypes = {}

export default SideAppBar
import React, {useEffect, useState} from 'react';
import {createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate, Route} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';

import theme from './Theme';
import RootContext from './services/context-states/root-context';

import AuthService from './services/auth';
import CareerDetailsPage from '@components/careers/CareerDetailsPage';
import ClusterDetailsPage from '@components/careers/ClusterDetailsPage';
import Home from '@components/Home';
import Landing from '@components/Landing';
import LibraryClustersPage from '@components/library/LibraryClustersPage';
import LibraryClusterDetailsPage from '@components/library/LibraryClusterDetailsPage';
import LikedVideosPage from '@components/videos/LikedVideosPage';
import Login from '@components/authentication/Login';
import NavigationBar from '@components/app-bar/NavigationBar';
import OrganizationManagementPage from '@components/admin/OrganizationManagementPage';
import PrivateRoute from './routers/PrivateRoute';
import ProfilePage from '@components/profile/ProfilePage';
import PublicRoute from './routers/PublicRoute';
import Register from '@components/authentication/Register';
import ResetPassword from '@components/authentication/ResetPassword';
import ResetPasswordFinish from '@components/authentication/ResetPasswordFinish';
import ReportPage from '@components/results/ReportPage';
import ShareReportPage from './components/results/ShareReportPage';
import VideoHistoryPage from '@components/videos/VideoHistoryPage'
import VideosPage from '@components/videos/VideosPage';
import ViewInsightReport from './components/results/ViewInsightReport';

const App = () => {
  const [token, setToken] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [billingInformation, setBillingInformation] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [accessFlags, setAccessFlags] = useState({
    enableManageBilling: false,
    enableManageUsers: false,
    showIncompleteProfile: false,
    showMissingLicense: false,
    showUnusedLicenses: false,
  });
  const tokenContext = {token, setToken};
  const userContext = {currentUser, setCurrentUser};
  const billingContext = {billingInformation, setBillingInformation};
  const messageContext = {error, setError, successMessage, setSuccessMessage};
  const accessFlagsContext = {accessFlags, setAccessFlags}
  const value = {billingContext, messageContext, accessFlagsContext, tokenContext, userContext}

  useEffect(() => {
    const authToken = AuthService.getCurrentUser()?.token;
    
    if (!token && !!authToken) {
      setToken(authToken);
    }

    if (!currentUser.userId && authToken) {
      const {userData} = AuthService.decodeUserToken(authToken);
      setCurrentUser(userData);
    }

  }, [currentUser.id, token])

  const authenticated = !!token || !!AuthService.getCurrentUser()?.token;

  const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
          <Route element={<NavigationBar ignoreLogin={false} />}>
            <Route element={<PublicRoute />}>
              <Route path="/" element={(authenticated) ? <Navigate to="/home" /> : <Landing />} />
              <Route path="/invite-accept" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-password/complete" element={<ResetPasswordFinish />} />
            </Route>
            <Route element={<PrivateRoute authenticated={authenticated} />}>
              <Route path="/home" element={<Home />} />
              <Route path="/admin/organization-management" element={< OrganizationManagementPage />} />
              <Route path="/careers/details/:userId/:careerClusterId/career/:detailId" element={<CareerDetailsPage />} />
              <Route path="/careers/details/:userId/:careerClusterId" element={<ClusterDetailsPage />} />
              <Route path='/library' element={<LibraryClustersPage />} />
              <Route path='/library/cluster/:clusterId' element={<LibraryClusterDetailsPage />} />
              <Route path="/report" element={<ReportPage />} />
              <Route path="/settings" element={<ProfilePage />} />
              <Route path="/share" element={<ShareReportPage />} />
              <Route path="/user-profile/:profileSearch" element={<ProfilePage loadPublic={true} />} />
              <Route path="/videos/watch" element={<VideosPage />} />
              <Route path="/videos/liked" element={<LikedVideosPage />} />
              <Route path="/videos/history" element={<VideoHistoryPage />} />
              <Route path="*" element={<Home />} />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path="*" element={<Landing />} />
            </Route>
          </Route>
          <Route element={<NavigationBar ignoreLogin={true} />}>
            <Route element={<PublicRoute />}>
              <Route path="/results/share" element={<ViewInsightReport />} />
            </Route>
          </Route>
        </Route>
    )
  )

  return (
    <ThemeProvider theme={theme}>
      <RootContext.Provider value={value}>
        <RouterProvider router={router}>

        </RouterProvider>
      </RootContext.Provider>
    </ThemeProvider>
  )
};

export default App;
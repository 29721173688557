// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
}

html body {
  padding: 0;
  margin: 0;
  overflow-x: inherit;
}

#main-private-container {
  padding: 15px;
  margin-left: 250px;
  width: 100%;
}

#private-global-alert {
  max-width: 100%;
  align-content: center;
}

#main-private-container-outlet {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEE;EACE,6EAAA;EACA,sBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,UAAA;EACA,SAAA;EACA,mBAAA;AACJ;;AAMA;EACI,aAAA;EACA,kBAAA;EACA,WAAA;AAHJ;;AAMA;EACG,eAAA;EACA,qBAAA;AAHH;;AAMA;EACI,cAAA;AAHJ","sourcesContent":["*, *::before, *::after {\n    box-sizing: inherit;\n  }\n\n  html {\n    /* this makes sure the padding and the border is included in the box sizing */\n    box-sizing: border-box;\n    overflow-x: hidden;\n  }\n  \n  html body {\n    padding: 0;\n    margin: 0;\n    overflow-x: inherit;\n  }\n\n#app {\n\n}\n\n#main-private-container {\n    padding: 15px;\n    margin-left: 250px;\n    width: 100%;\n}\n\n#private-global-alert {\n   max-width: 100%;\n   align-content: center;\n}\n\n#main-private-container-outlet {\n    margin-left: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

import {videoApi} from '@apis';

const playerStates = {
    '-1': 'unstarted',
    0: 'ended',
    1: 'started',
    2: 'paused',
    3: 'buffering',
    4: null,
    5: 'video cued',
}

const VideoPlayer = ({video, autoplay = 0, addtionalOptions, additionalVars, onEndFunc, viewOnly=false, height = '100%', width = '100%'}) => {
    const recordStateChange = async (videoStateNumber) => {

        if(!video.watchId) {
            return;
        }

        const videoStateParam = playerStates[videoStateNumber];
        await videoApi.watchLog.addVideoHistory(videoStateParam, {
            watchId: video.watchId,
            eventOn: new Date()
        });
    }

    const onPlayerStateChange = (event) => {
        switch (event?.data) {
            case -1:
                break;
            case 0:
                recordStateChange(event.data);
                onEndFunc();
                break;
            case 1:
            case 2:
                recordStateChange(event.data);
                break
            case 3:
            case 5:
                break;
            default:
                return;
        }
    }

    const opts = {
        width,
        height,
        playerVars: {
            autoplay,
            ...additionalVars

        },
        disabled: true,
        ...addtionalOptions
    };

    const showView = viewOnly ? {pointerEvents: 'none'} : null;

    return (
        <YouTube
            videoId={video.id}
            opts={opts}
            onStateChange={onPlayerStateChange}
            disabled={true}
            style={{flexGrow: 1, ...showView}}
        />
    )
}

VideoPlayer.propTypes = {
    addtionalOptions: PropTypes.object,
    additionalVars: PropTypes.object,
    autoplay: PropTypes.number,
    onEndFunc: PropTypes.func,
    video: PropTypes.object.isRequired,
    viewOnly: PropTypes.bool,
    height: PropTypes.number | PropTypes.string,
    width: PropTypes.number | PropTypes.string
}

export default VideoPlayer
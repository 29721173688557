// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-box {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.scroll-box__wrapper {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.scroll-box__wrapper::-webkit-scrollbar {
  display: none;
}

.scroll-box__container {
  height: 100%;
  display: inline-flex;
}`, "",{"version":3,"sources":["webpack://./src/elements/organisms/horizontal-scroll-box/horizontalScrollBox.css"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AAEE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,sBAAA;EACA,wBAAA;EACA,8BAAA;AACJ;;AAEE;EACE,aAAA;AACJ;;AAEE;EACE,YAAA;EACA,oBAAA;AACJ","sourcesContent":[".scroll-box {\n    position: relative;\n    width: 100%;\n    overflow: hidden;\n  }\n  \n  .scroll-box__wrapper {\n    width: 100%;\n    height: 100%;\n    overflow-y: hidden;\n    overflow-x: scroll;\n    box-sizing: border-box;\n    -ms-overflow-style: none;\n    overflow: -moz-scrollbars-none;\n  }\n  \n  .scroll-box__wrapper::-webkit-scrollbar {\n    display: none;\n  }\n  \n  .scroll-box__container {\n    height: 100%;\n    display: inline-flex;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {createTheme} from '@mui/material/styles';

export default createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0709FF',
      contrast: '#ffffff'
    },
    secondary: {
      main: '#E53935',
    },
    background: {
      default: '#F7F7F8',
    },
    text: {
      secondary: '#6C7B89',
    },
    info: {
      main: '#2196F3',
    },
    divider: '#D5D5D6',
  },
  typography: {
    fontFamily: 'Inter'
  },

});
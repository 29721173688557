import React, {useContext, useEffect, useState} from 'react';
import {useBlocker} from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon  from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import {videoApi} from '@apis';
import VideoPlayer from './VideoPlayer';

import {Button, TrustMeter} from '@elements';

import {truncateString} from '../../helpers/strings';

import RootContext from '../../services/context-states/root-context';

const VideosPage = () => {
    const {userContext} = useContext(RootContext);
    const {currentUser} = userContext;

    const [video, setVideo] = useState({});
    const [videoEndTimestamp, setVideoEndTimestamp] = useState(null);
    const [rating, setRating] = useState(0);
    const [watchCount, setWatchCount] = useState(-1);
    const [showMore, setShowMore] = useState(false);

    const ratingOptions = [
        {value: 1, label: 'Not Interesting'},
        {value: 2, label: 'Slighly Interesting'},
        {value: 3, label: 'Somewhat Interesting'},
        {value: 4, label: 'Quite Interesting'},
        {value: 5, label: 'Very Interesting'},
    ];

    useEffect(() => 
        {
            if(watchCount === -1 && currentUser.id) {
                videoApi.watchLog.getHistory('count', currentUser.id)
                .then(data => {
                    console.log('data', data)
                    setWatchCount(data)

                })
                .catch((error) => {
                console.log('error', error)
                })
                
                fetchNextVideo()
            }
        }, [currentUser])

    // eslint-disable-next-line no-unused-vars
    const blocker = useBlocker(() => {
        videoApi.watchLog.updateVideoLog({
            rating: 0,
            ended: videoEndTimestamp || new Date(),
            watchId: video.watchId
        });

        return false;
    })
    
    const fetchNextVideo = () => {
        videoApi.videos.getNextVideo()
            .then(data => {
                setupViewingHistory(data);
                setVideo(data);
            })
            .catch(() => {})
    }

    const setupViewingHistory = (data) => {
        videoApi.watchLog.addVideoLog({
            watchId: data.watchId,
            source: 'youtube',
            sourceId: data.id,
            url: data.url
        })
            .then(() => setRating(0))
            .catch(() => {})
    }

    const submitVideoRating = async () => {
        await videoApi.watchLog.updateVideoLog({
            rating,
            ended: videoEndTimestamp || new Date(),
            watchId: video.watchId
        });

        setWatchCount(watchCount + 1);
        fetchNextVideo();
        setVideoEndTimestamp(null);
        setRating(0);
        setShowMore(false);
    }

    const endOfVideoEvent = () => {
        setVideoEndTimestamp(new Date());
    }

    const rateVideo = (rating) => setRating(rating);

    return (
        <div style={{display: 'flex', flexDirection:'row', gap: '20px', justifyContent: 'flex-start', alignItems: 'stretch', height: 800, minHeight: 650, maxHeight: 800}}>
            {/*VIDEO PLAYER*/}
                <div style={{display: 'flex', flexDirection: 'column', width: '70%'}}>
                    <Typography sx={{fontSize: 18, fontWeight: 'bold', color: 'black'}}>
                        {truncateString(video.title, 100)}
                    </Typography>
                    <VideoPlayer style={{flexGrow: 1}} video={video} autoplay={1} onEndFunc={endOfVideoEvent} />
                </div>
            {/*VIDEO PLAYER*/}

            {/*VIDEO DETAILS*/}
                <div style={{display: 'flex', flexDirection: 'column', width:'30%', borderRadius: '10px', boxShadow: '0px 1px 9.6px 0px #0709FF42', minWidth: 300}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, padding: '15px'}}>
                        <Typography sx={{fontSize: 15, fontWeight: 'bold', color: 'black'}}>
                            Career type:
                        </Typography> 
                        <Typography sx={{fontSize: 16, color: 'black'}}>
                            {video.careerCluster?.title}
                        </Typography>
                        <Typography sx={{fontSize: 15, fontWeight: 'bold', color: 'black', marginTop: 2}}>
                            Careers shown in this video:
                        </Typography> 
                        <Typography sx={{fontSize: 16, color: 'black'}}>
                            {video.onetData?.title}
                        </Typography>
                        <Typography sx={{fontSize: 15, fontWeight: 'bold', color: 'black', marginTop: 2}}>
                            Job Description:
                        </Typography>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: 160, overflowY: showMore ? 'scroll' : 'auto'}}>
                            <Typography sx={{fontSize: 16, color: 'black'}}>
                                {showMore ? video.onetData?.description : truncateString(video.onetData?.description, 200)}
                            </Typography>
                            {
                                video.onetData?.description?.length > 200 && (
                                    <Button format='tertiary' style={{color: '#ADADAE'}} text={showMore ? 'Show Less' : 'Show More'} onClick={() => setShowMore(!showMore)} />
                                )
                            }
                        </div> 
                        <Divider style={{marginTop: 20, marginBottom: 20, color:'#C4C4C4'}} />
                        <Typography sx={{fontSize: 16, color: 'black'}}>
                            Did you find this career interesting?
                        </Typography>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 1}}>
                            {ratingOptions.map(option => (
                                <div key={option.value} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'flex-start'}}>
                                    <Checkbox
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={<CheckCircleIcon />}
                                        checked={rating === option.value}
                                        onChange={() => {rating === option.value ? rateVideo(0) : rateVideo(option.value)}}
                                    />
                                    <Typography sx={{fontSize: 14, color: 'black'}}>
                                        {option.value} - {option.label}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                        <Divider style={{marginTop: 20, marginBottom: 20, color:'#C4C4C4'}} />
                        <Button disabled={rating === 0} format='primary' text='Next Video' style={{height: 50, borderRadius: 2, '&.Mui-disabled': {background: '#ADADAE', color: '#FFFFFF'}}} onClick={async () => await submitVideoRating()} />
                    </div>
                    <TrustMeter fullAmount={32} trustAmount={watchCount} style={{algnSelf: 'flex-end', height: 60, borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}/>
                </div>
            {/*VIDEO DETAILS*/}
        </div>
    )
}

export default VideosPage
import React, {useContext} from 'react';
import {useSearchParams} from 'react-router-dom';

import Paper from '@mui/material/Paper';

import RootContext from '../../services/context-states/root-context';
import {Tabs} from '@elements';
import {AccountInfo, DataPrivacy, Display, Payments, UserManagement} from './views';

function ProfilePage () {
  const {messageContext, accessFlagsContext, userContext} = useContext(RootContext);
  const {setError} = messageContext;
  const{accessFlags} = accessFlagsContext;
  const {currentUser} = userContext;
  const [searchParams] = useSearchParams();

  const tabToShow = searchParams.get('show');

  const getTabsToLoad = () => {

    const tabsToEvaluate = [
      {name: 'Account Info', view: <AccountInfo profile={currentUser}/>, display: true, showBadge: false, badgeValue: -1},
      {name: 'Display', view: <Display />, display: false, showBadge: false, badgeValue: -1},
      {name: 'Billing', view: <Payments />, display: accessFlags.enableManageBilling, showBadge: accessFlags.showIncompleteProfile, badgeValue: -1},
      {name: 'User Management', view: <UserManagement />, display: accessFlags.enableManageUsers, showBadge: accessFlags.showUnusedLicenses, badgeValue: -1},
      {name: 'Data Privacy', view: <DataPrivacy />, display: true, showBadge: false, badgeValue: -1}
    ];

    const tabsToReturn = {};
    let tabIndex = 0;

    tabsToEvaluate.forEach((tab) => {
      if(tab.display) {
        tabsToReturn[tabIndex] = tab;
        tabIndex++;
      }
    });

    return tabsToReturn;
  }

  const getInitialTab = (tabs) => {

    if(tabToShow) {

      const displayIndex = Object.keys(tabs).find(key => tabs[key].name === tabToShow);

      if(displayIndex) {
        return parseInt(displayIndex);
      }
    }

    return 0;
  }

  // showTab is a bool property I can use here as I work on this
  const tabsToLoad = getTabsToLoad();
  const initialTabKey = getInitialTab(tabsToLoad);

  // Reset the URL since initalTabKey is set
  window.history.replaceState({}, document.title, '/settings');

  setError(!currentUser ? 'We are unable to locate your information at this time' : '');

  return (
    <Paper elevation={3} sx={{background:'white', minWidth:'800px', maxWidth:'50%', maxHeight:'800px', minHeight:'800px'}}>
      <Tabs reloadKey={currentUser.id} tabsObj={tabsToLoad} initialSelectedTab={initialTabKey} sx={{height:'100%'}}/>
    </Paper >
  );
}

ProfilePage.propTypes = {
}

export default ProfilePage
import React, {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom';
import RootContext from '../services/context-states/root-context';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import {exampleMenu, exampleWatch, exampleRatings, exampleResults, iconLightBulb, iconHammerWrench, iconRightArrowButton} from '../assets';

import {enableVideo} from '../helpers/entitlements';

const Home = () => {
  const {billingContext, userContext} = useContext(RootContext)
  const {billingInformation} = billingContext;
  const {currentUser} = userContext;

  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showHowItWorksModal, setShowHowItWorksModal] = useState(false);
  const [setupStep, setSetupStep] = useState(0);

  const navigate = useNavigate();

  const buildCard = (icon, title, description, modal) => {
    return (
      <Box sx={{boxShadow: 3, borderRadius: 2, paddingTop: 8, paddingBottom: 8, paddingLeft: 4, paddingRight: 4, backgroundColor: 'white', width: '300px', textAlign:'center'}}>
        <Stack spacing={2} sx={{alignContent: 'center', alignItems: 'center'}}>
          <img src={icon} alt={'icon'} style={{width: 50, height: 50, marginBottom: 10}}/>
          <Typography sx={{fontWeight: 'bold', fontSize: 22, marginTop: 2}}>{title}</Typography>
          <Typography sx={{fontSize: 18, marginTop: 2}}>{description}</Typography>
          <IconButton onClick={modal} src={iconRightArrowButton}>
              <ArrowCircleRightOutlinedIcon sx={{width: 50, height: 50}}/>
          </IconButton>
        </Stack>
      </Box>
    )
  }

  const handleSetupModalClose = () => {
    setShowSetupModal(false);
    setSetupStep(0);
  }

  const setupModalData = [
    {
      image: exampleMenu,
      caption: 'Navigate to the Watch Page under the Videos tab.'
    },
    {
      image: exampleWatch,
      caption: 'Watch videos to learn about different careers and give a Yes or No reaction. Each reaction you give contributes to a more accurate career recommendation.'
    },
    {
      image: exampleRatings,
      caption: 'When you’re done with the video, you can proceed to the next video.'
    },
    {
      image: exampleResults,
      caption: 'After watching 30 videos, you’ll be prompted to go to your Results Page to see your career insights.'
    }
  ]

  const handleStepMove = (direction) => {
    if (direction === 'forward') {
      if (setupStep < setupModalData.length - 1) {
        setSetupStep(setupStep + 1);
      }
    } else {
      if (setupStep > 0) {
        setSetupStep(setupStep - 1);
      }
    }
  }

  const setupSteps = () => {
    return (
      <div style={{display:'grid', gridTemplateRows:'auto minmax(0, 1fr)'}}>
        <div style={{maxHeight:'350px', minHeight:'350px', display:'grid', gridTemplateRows:'auto minmax(0, 1fr)', marginTop: 8}}>
          <Box sx={{flexGrow:1, display:'flex', alignItems:'flex-start', alignContent:'center', justifyContent:'center', paddingTop:2, textAlign:'center'}}>
            <img src={setupModalData[setupStep].image} alt={'example'} />
          </Box>
        </div>
        <div style={{maxHeight:'160px', minHeight:'160px', display:'grid', gridTemplateRows:'auto minmax(0, 1fr)', backgroundColor:'white', paddingLeft:2, paddingRight:2}}>
          <Box sx={{display:'flex', height:'160px', width:'100%'}}>
            <Box sx={{display:'flex', alignItems:'center', alignContent:'center', justifyContent:'center'}}>
              <IconButton onClick={() => handleStepMove('back')} sx={{color: (theme) => theme.palette.grey[500], visibility: setupStep > 0 ? 'visible' : 'hidden'}}>
                <KeyboardArrowLeft sx={{fontSize:'50px'}} />
              </IconButton>
            </Box>
            <Box sx={{display:'flex', flex:1, flexDirection:'column', padding:2}}>
              <Box sx={{flexGrow:1, display:'flex', alignItems:'flex-start', alignContent:'center', justifyContent:'center', paddingTop:2, textAlign:'center'}}>
                <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>{setupModalData[setupStep].caption}</Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:'flex-end', alignContent:'center', justifyContent:'center', paddingBottom:1}}>
                {
                  setupStep + 1 === setupModalData.length ? (
                    <Button variant='contained' onClick={() => navigate('/videos/watch')} disabled={!enableVideo(currentUser, billingInformation)}>Start watching videos</Button>
                  ) : (
                    <Typography sx={{fontSize: 16, fontWeight: 'bold', color:'lightgray'}}>{setupStep + 1} / {setupModalData.length}</Typography>
                  )
                }
              </Box>
            </Box>
            <Box sx={{display:'flex', alignItems:'center', alignContent:'center', justifyContent:'center'}}>
              <IconButton onClick={() => handleStepMove('forward')} sx={{color: (theme) => theme.palette.grey[500], visibility: setupStep + 1 < setupModalData.length ? 'visible' : 'hidden'}}>
                <KeyboardArrowRight sx={{fontSize:'50px'}} />
              </IconButton>
            </Box>
          </Box>
        </div>
      </div>
    )
  }

  return (
    <Grid container spacing={2} sx={{marginLeft: 1}}>
      <Grid item xs={12}>
        <Dialog open={showSetupModal} onClose={() => handleSetupModalClose()} sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              background: '#EEEDFF',
              width: '100%',
              maxWidth: '650px',
              height: '100%',
              maxHeight: '550px',
            },
          },
        }}>
          <DialogTitle>
            <IconButton aria-label="close" onClick={() => handleSetupModalClose()} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {setupSteps()}
        </Dialog>
        <Dialog open={showHowItWorksModal} onClose={() => setShowHowItWorksModal(false)} sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '650px',  // Set your width here
            },
          },
        }}>
          <DialogTitle>
            <IconButton aria-label="close" onClick={() => setShowHowItWorksModal(false)} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Stack spacing={2} sx={{padding: 4}}>
            <Typography sx={{fontWeight: 'bold', fontSize: 24}}>How your Results are Calculated</Typography>
            <Typography sx={{fontSize: 20}}>
              The career paths shown to you in your Results page are calculated from your reactions to the videos. 
              {<br/>}{<br/>}
              Every video you&apos;ve liked will increase a career path&apos;s ranking and every video that you&apos;ve disliked will decrease a career path&apos;s ranking. 
              {<br/>}{<br/>}
              The selection of curated videos will be refreshed based on your results after you have watched 30 videos.
            </Typography>
          </Stack>
        </Dialog>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{fontWeight: 'bold', fontSize: 38}}>Welcome back {currentUser.firstName}!</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{fontSize: 28, fontWeight: 'bold', marginTop: 2}}>Guides to get started</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={3} direction={'row'}>
          {buildCard(iconLightBulb, 'Getting Started', 'Learn how to begin your CareerAtlas journey.', () => setShowSetupModal(true))}
          {buildCard(iconHammerWrench, 'How It Works', 'Find out how we calculate your results.', () => setShowHowItWorksModal(true))}
        </Stack>
      </Grid>
    </Grid>
  )
}

export default Home
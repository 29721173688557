import React from 'react'
import {Controller} from 'react-hook-form';
import PropTypes from 'prop-types'

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';


export const GlobalChipMultiSelect = (props) => {
  const {id, name, control, label, options, sx} = props

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
  };

  return (
    <Controller
      name={ name }
      control={ control }
      render={ ({
        field: {onChange, value}
      }) => (
        <FormControl sx={sx}>
            <InputLabel id="multiple-chip-label">{label}</InputLabel>
            <Select
            labelId="multiple-chip-label"
            id={id}
            multiple
            value={value}
            onChange={onChange}
            input={<OutlinedInput id="multiple-chip" label={label} />}
            renderValue={(selected) => (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                {selected.map((sValue) => (
                    <Chip key={sValue} label={sValue} />
                ))}
                </Box>
            )}
            MenuProps={MenuProps}>
            {options.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
      ) }
    />
  )
}

GlobalChipMultiSelect.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.any,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    sx: PropTypes.object
  }

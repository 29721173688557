import React from 'react'
import {Controller} from 'react-hook-form';
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

/* Passthroughs should be left for the following:
- variant
- fullWidth
- size
*/

export const GlobalCheckbox = (props) => {
  const {name, control, id, label, ...passthroughProps} = props
  return (
    <Controller
      name={ name }
      control={ control }
      render={ ({
        field: {onChange, value}
      }) => (
        <FormControlLabel 
          control={<Checkbox color='primary' checked={value} onChange={onChange} />} 
          id={id}
          key={id} 
          label={ label || startCase(id) }
          { ...passthroughProps } />
      ) }
    />
  )
}

GlobalCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.any,
  defaultValue: PropTypes.string,
  label: PropTypes.string
}

import React from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

export function TabPanel (props) {
    const {children, value, ...rest} = props;

    return (
        <Box
            component="div"
            role="tabpanel"
            id={ `tabpanel-${value}` }
            aria-labelledby={ `tab-${value}` }
            { ...rest }
        >
            { children }
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
};
import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import useHorizontalScrollBox from './useHorizontalScrollBox';
import './horizontalScrollBox.css';

export function GlobalHorizontalScrollBox({toRender}) {
  const scrollWrapperRef = useRef();
  const {isDragging} = useHorizontalScrollBox(scrollWrapperRef);
  return (
    <div className="scroll-box">
      <div className="scroll-box__wrapper" ref={scrollWrapperRef}>
        <div className="scroll-box__container" role="list" style={{pointerEvents: isDragging ? 'none' : undefined}}>
          {toRender.map((child, i) => (
            <div role="listitem" key={`scroll-box-item-${i}`} className="scroll-box__item">
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

GlobalHorizontalScrollBox.propTypes = {
  toRender: PropTypes.arrayOf(PropTypes.object),
};
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {TabPanel} from './TabPanel';

const COMPONENT_DEFAULT_STYLE = {
    width: '100%',
}

const TAB_DEFAULT_STYLE = {
    borderBottom: 1,
    borderColor: 'divider',
}

const SUBTAB_BOX_DEFAULT_STYLE = {
    borderBottom: 1,
    borderColor: 'divider',
    marginLeft: 2,
    marginRight: 2,
}

const SUBTAB_DEFAULT_STYLE = {
    marginLeft: 5,
    marginRight: 5,
    marginTop: -1,
}

const SUBTAB_DEFAULT_TAB_STYLE = {
    paddingBottom: 0,
}

const VIEW_DEFAULT_STYLE = {
    p: 5,
    minHeight: 200
}

export function GlobalTabs ({tabsObj, styles, initialSelectedTab = 0}) {
    const [value, setValue] = useState(0);
    const [subtabValue, setSubtabValue] = useState(0);
    const [tabsToRender, setTabsToRender] = useState([]);
    const [subtabsToRender, setSubtabsToRender] = useState([])
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {

        const allowedTabs = [];

        Object.keys(tabsObj).forEach((key) => {

            const tabToRender = tabsObj[ key ];

            if (tabToRender.showTab === undefined
                || tabToRender.showTab === null
                || tabToRender.showTab)
            {
                allowedTabs.push(tabToRender);
            }
        });

        setTabsToRender(allowedTabs);

    }, [tabsObj]);

    useEffect(() => {
        const subtabsObj = tabsToRender[ value ]?.subtabs
        const subTabs = [];

        // only need to reset to  empty array if it needs to be and isn't one
        if (!subtabsObj)
        {
            if (subtabsToRender.length)
            {
                setSubtabsToRender([])
            }
            return
        }

        Object.keys(subtabsObj).forEach((key) => {

            const tabToRender = subtabsObj[ key ];

            if (tabToRender.showTab === undefined
                || tabToRender.showTab === null
                || tabToRender.showTab)
            {
                subTabs.push(tabToRender);
            }
        });

        setSubtabsToRender(subTabs);

    }, [value]);

    useEffect(() => {
        if(tabsToRender.length && initialSelectedTab !== 0 && !hasLoaded) {
            setValue(initialSelectedTab);
            setHasLoaded(true);
        }
    }, [tabsToRender]);

    const handleChange = (event, newValue) => setValue(newValue);

    const handleSubtabChange = (event, newValue) => setSubtabValue(newValue);

    const BadgeComponent = (tab) => (
        <Badge badgeContent={tab.badgeValue}
                invisible={!tab.showBadge}
                variant={tab.badgeValue === -1 ? 'dot' : 'standard'}
                color="error">
                    {tab.name}
        </Badge>
    );

    return (
        <Box sx={ styles?.component || COMPONENT_DEFAULT_STYLE }>
            <Box sx={ styles?.tab || TAB_DEFAULT_STYLE }>
                <Tabs value={ value } onChange={ handleChange } aria-label="profile tabs" allowScrollButtonsMobile>
                    { tabsToRender.map(tab => {
                        return <Tab
                            key={ tab.name }
                            label={BadgeComponent(tab)}
                            id={ `${tab.name}-tab-${tab.key}` }
                            aria-controls={ `${tab.name}-tabpanel-${tab.key}` }
                        />
                    }) }
                </Tabs>
            </Box>

            { !!subtabsToRender.length &&
                <Box sx={ styles?.tab || SUBTAB_BOX_DEFAULT_STYLE }>
                    <Tabs value={ subtabValue } onChange={ handleSubtabChange } aria-label="profile tabs" sx={ styles?.subtab || SUBTAB_DEFAULT_STYLE } allowScrollButtonsMobile>
                        { subtabsToRender.map(tab => {
                            return <Tab
                                key={ tab.name }
                                label={BadgeComponent(tab)}
                                id={ `${tab.name}-tab-${tab.key}` }
                                aria-controls={ `${tab.name}-tabpanel-${tab.key}` }
                                sx={ SUBTAB_DEFAULT_TAB_STYLE }
                            />
                        }) }
                    </Tabs>
                </Box>
            }

            <TabPanel value={ value } sx={ styles?.view || VIEW_DEFAULT_STYLE }>
                {
                    subtabsToRender.length
                        ? subtabsToRender[ subtabValue ].view
                        : tabsToRender.length
                            ? tabsToRender[ value ].view
                            : null
                }
            </TabPanel>
        </Box>
    );
}

GlobalTabs.propTypes = {
    tabsObj: PropTypes.object,
    styles: PropTypes.object,
    initialSelectedTab: PropTypes.number
}
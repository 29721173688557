import React  from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

export const GlobalLoading = (props) => {

  const {message} = props;

  return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh'}}>
        <CircularProgress sx={{width: 75, height: 75}}/>
        <Typography sx={{fontSize: 20, marginLeft: 2}}>{!message ? 'Loading...' : message}</Typography>
      </div>
  );
}

GlobalLoading.propTypes = {
  message: PropTypes.string
};
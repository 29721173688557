import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Image} from 'mui-image';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import Copyright from '../Copyright';
import {landingImage} from '../../assets'
import RootContext from '../../services/context-states/root-context';
import AuthService from '../../services/auth';
import {audience, setResetApiAuth, userApi} from '@apis';

const Login = () => {
  const {tokenContext, userContext} = useContext(RootContext);
  const {setToken} = tokenContext;
  const {setCurrentUser} = userContext;
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setError('');
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const submissionsData = {
      email: data.get('email'),
      password: data.get('password'),
    };

    userApi.account.authorize(
      {
        email: submissionsData.email,
        password: submissionsData.password,
        audience
      }, 'user').then(data => {
        if (data?.token)
        {
          localStorage.setItem('user', JSON.stringify({
            ...data,
            expiry: new Date().getTime() + 5000
          }))

          setToken(data.token);
          const {userData} = AuthService.decodeUserToken(data.token);
          setCurrentUser(userData);
          setResetApiAuth();
          navigate('/home');
        } else
        {
          setError('login unsuccessful, please try again')
        }
      })
      .catch((err) => setError(err.message || 'invalid username or password'))
  };

  return (
    <Grid container direction='row' justifyContent="space-evenly" spacing={ 5 } sx={ {mt: 5} }>
      <Grid center item md={ 6 }>

        { error && <Alert severity='error' sx={ {mb: 1} }>{ error }</Alert> }

        <Typography component='h5' variant='h5' sx={ {fontWeight: 900} }>
          Welcome Back!
        </Typography>
        <Typography component='p' variant='body1' >
          Log into your Future Gen account.
        </Typography>
        <Box component='form' onSubmit={ handleSubmit } noValidate sx={ {mt: 1} }>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
          />
          <FormControlLabel
            control={ <Checkbox value='remember' color='primary' /> }
            label='Remember me'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={ {mt: 3, mb: 2} }
          >
            Sign In
          </Button>
          <Grid container sx={ {justifyContent: 'space-evenly'} } >
            <Grid item>
              <Link
                component='button'
                variant='body1'
                onClick={ (event) => { event.preventDefault(); navigate('/reset-password');}}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                component='button'
                variant='body1'
                onClick={ (event) => { event.preventDefault(); navigate('/register') }}
              >
                Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item md display={ {xs: 'none', md: 'block'} }>
        <Image src={ landingImage } />
      </Grid>

      <Copyright />
    </Grid>
  )
}

Login.propTypes = {
  actions: PropTypes.shape({
    changeComponent: PropTypes.func,
    closeModal: PropTypes.func,
    toggleLoggedIn: PropTypes.func,
  }),
}

export default Login
import {GlobalButton as Button} from './Button';
import {GlobalCheckbox as FormCheckbox} from './FormCheckbox';
import {GlobalFormInput as FormInput} from './FormInput';
import {GlobalFormSelect as FormSelect} from './FormSelect';
import {GlobalRadio as Radio} from './Radio';

export {
    Button,
    FormCheckbox,
    FormInput,
    FormSelect,
    Radio
};
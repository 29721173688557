import React from 'react'
import {useNavigate} from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {logoSquare} from '../../assets';



const TopAppBar = () => {
    const navigate = useNavigate();

    return (
        <AppBar position='sticky' sx={{backgroundColor: 'primary.contrast', width: '100vw', maxHeight: 65}}>
            <Toolbar sx={{justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', minWidth: 250}}>
                    <Box
                        component="img"
                        sx={{
                            maxWidth: 50,
                            cursor: 'pointer',
                        }}
                        alt="Future Gen logo"
                        src={logoSquare}
                        onClick={() => navigate('/')}

                    />
                    <Typography component='h4' variant='h4' sx={{color: 'black', fontWeight: 900}}>
                        Future Gen
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

TopAppBar.propTypes = {}

export default TopAppBar
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import styles from './borderedSection.module.scss';

export const GlobalBorderedSection = ({icon, title, children}) => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.header}>
                <div className={styles.headerBorderBefore}></div>
                {(icon || title) && (
                    <div className={styles.headerTitle}>
                        {icon && <SvgIcon component={icon} />}
                        {title && <span className={styles.title}>{title}</span>}
                    </div>
                )}
                <div className={styles.headerBorderAfter}></div>
            </div>
            <div className={styles.childrenContainer}>{children}</div>
        </div>
    );
}


GlobalBorderedSection.propTypes = {
    icon: PropTypes.elementType,
    title: PropTypes.string,
    children: PropTypes.node,
};
import React, {Component} from 'react'

export class OrganizationManagementPage extends Component {
  render() {
    return (
      <div>OrganizationManagementPage</div>
    )
  }
}

export default OrganizationManagementPage
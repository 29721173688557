import {createContext} from 'react'

const RootContext = createContext({
    token: '',
    setToken: () => { },
    currentUser: {},
    setCurrentUser: () => { },
    billingInformaton: null,
    setBillingInformation: () => { },
    error: '',
    setError: () => { },
    accessFlags: {},
    setAccessFlags: () => { },
});

export default RootContext;
import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography';

const Notifications = ({currentUser}) => {

    // const {additionalInformation: {
    //     settings: {
    //         receiveEmail,
    //         receiveSms
    //     }
    // }} = currentUser;

    return (
        <>
            <Typography variant='h5' fontWeight={900} gutterBottom>
                Email Notifications
            </Typography>
            <Typography variant='body1'>
                {currentUser?.additionalInformation?.settings?.receiveEmail || 'No email settings provided'}
            </Typography>

            <Typography variant='body1'>
                {currentUser?.additionalInformation?.settings?.receiveSms || 'No sms settings provided'}
            </Typography>
        </>
    )
}

Notifications.propTypes = {
    currentUser: PropTypes.object.isRequired
}


export default Notifications
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zvN4udUj3NmVkEPmkidQ {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  border-left: 1px solid #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
  border-right: 1px solid #b2b2b2;
  border-radius: 5px;
  margin: 1em;
}
.zvN4udUj3NmVkEPmkidQ .TveQjF42TsaHpg_uMM8C {
  padding: 1em;
}
.zvN4udUj3NmVkEPmkidQ .RGa2x4tKrrgjn67e5Tpg {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}
.zvN4udUj3NmVkEPmkidQ .RGa2x4tKrrgjn67e5Tpg .YaEIeqMizTj1pDpKLOVk {
  border-top: 1px solid #b2b2b2;
  width: 1em;
  border-top-left-radius: 5px;
}
.zvN4udUj3NmVkEPmkidQ .RGa2x4tKrrgjn67e5Tpg .aAqXMIlQuL0SfgOi53Ed {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.25em;
  width: fit-content;
  height: 2em;
  margin: -1em 0.5em 0em 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  font-weight: 600;
}
.zvN4udUj3NmVkEPmkidQ .RGa2x4tKrrgjn67e5Tpg .llLLvS9pRXsH6kXRYbNw {
  border-top: 1px solid #b2b2b2;
  width: 1em;
  flex-grow: 2;
  border-top-right-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/elements/organisms/bordered-section/borderedSection.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,eAAA;EAEA,8BAAA;EACA,gCAAA;EACA,+BAAA;EACA,kBAAA;EACA,WAAA;AAFJ;AAII;EACI,YAAA;AAFR;AAKI;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;AAHR;AAKQ;EACI,6BAAA;EACA,UAAA;EACA,2BAAA;AAHZ;AAMQ;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;EACA,gBAAA;AAJZ;AAOQ;EACI,6BAAA;EACA,UAAA;EACA,YAAA;EACA,4BAAA;AALZ","sourcesContent":["$border-color: #b2b2b2;\n\n.mainContainer {\n    display: flex;\n    flex-direction: column;\n    max-width: 100%;\n\n    border-left: 1px solid $border-color;\n    border-bottom: 1px solid $border-color;\n    border-right: 1px solid $border-color;\n    border-radius: 5px;\n    margin: 1em;\n\n    .childrenContainer {\n        padding: 1em;\n    }\n\n    .header {\n        display: flex;\n        flex-direction: row;\n        width: 100% !important;\n\n        .headerBorderBefore {\n            border-top: 1px solid $border-color;\n            width: 1em;\n            border-top-left-radius: 5px;\n        }\n\n        .headerTitle {\n            display: flex;\n            flex-direction: row;\n            flex-wrap: nowrap;\n            align-items: center;\n            gap: 0.25em;\n            width: fit-content;\n            height: 2em;\n            margin: -1em 0.5em 0em 0.5em;\n            overflow: hidden;\n            text-overflow: ellipsis;\n            font-size: 1em;\n            font-weight: 600;\n        }\n\n        .headerBorderAfter {\n            border-top: 1px solid $border-color;\n            width: 1em;\n            flex-grow: 2;\n            border-top-right-radius: 5px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `zvN4udUj3NmVkEPmkidQ`,
	"childrenContainer": `TveQjF42TsaHpg_uMM8C`,
	"header": `RGa2x4tKrrgjn67e5Tpg`,
	"headerBorderBefore": `YaEIeqMizTj1pDpKLOVk`,
	"headerTitle": `aAqXMIlQuL0SfgOi53Ed`,
	"headerBorderAfter": `llLLvS9pRXsH6kXRYbNw`
};
export default ___CSS_LOADER_EXPORT___;

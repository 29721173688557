import React, {useContext, useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import CloseIcon from '@mui/icons-material/Close';

import {videoApi} from '@apis';
import {Button, Card, Modal} from '@elements';
import VideoPlayer from './VideoPlayer';
import RootContext from '../../services/context-states/root-context';



const DEFAULT_PAGINATION = {
    pageCount: 1,
    pageNumber: 1,
    totalCount: 0
}

const VIDEO_OPTIONS = {
    height: '200',
    width: '200'
}

const VIDEO_PLAYER_VARS = {
    controls: 0,
    disablekb: 1,
}

const VideoHistoryPage = () => {
    const {userContext} = useContext(RootContext);
    const {currentUser} = userContext;
    const [videoHistory, setVideoHistory] = useState([]);
    const [displayRewatchModal, toggleRewatchModal] = useState(false);
    const [rewatchVideoData, setRewatchVideoData] = useState(null);
    const [pagination, updatePagination] = useState(DEFAULT_PAGINATION);

    useEffect(() => fetchVideoHistory(pagination.pageNumber), [currentUser])

    const fetchVideoHistory = (page) => {
        if (currentUser?.id) {
            videoApi.watchLog.getHistory('paged', currentUser.id, page, 12, 'yes')
                .then(data => {
                    console.log('data', data)
                    const {results, ...paginationData} = data
                    setVideoHistory(results);
                    updatePagination(paginationData);
                })
                .catch(() => {})
        }
    }

    const rewatchVideo = (video) => {
        setRewatchVideoData({
            id: video.sourceId,
            watchId: video.watchId
        });
        toggleRewatchModal(true);
    }

    const closeModal = () => {
        toggleRewatchModal(false);
        setRewatchVideoData(null);
    }

    const nextPage = () => fetchVideoHistory(pagination.pageNumber + 1);
    const prevPage = () => fetchVideoHistory(pagination.pageNumber - 1);

    if (videoHistory?.length) {
        return (
            <>
                <Modal
                    open={displayRewatchModal}
                    onClose={closeModal}
                    // modalTitle={`Rewatch ${rewatchVideoData.title}`}
                    modalTitle='Rewatch Video'
                    modalDescription='Rewatch selected video'
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: 700,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 5,
                        p: 4,
                    }}>
                        <Stack spacing={2}>
                            <IconButton aria-label="close" onClick={() => closeModal()} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                                <CloseIcon />
                            </IconButton>
                           {rewatchVideoData?.id && <VideoPlayer video={rewatchVideoData} autoplay={1} />}
                        </Stack>
                </Modal>
                <Grid display='flex' justifyContent='center' container spacing={4} columns={12}>
                    <Grid align='center' item xs={12}>
                        <Typography component='h3' variant='h3' sx={{fontWeight: 900}}>
                            Watch History
                        </Typography>
                    </Grid>


                    {/* Pagination Buttons For Extra Small & Small Screens Only */}
                    <Grid item xs={4} sm={3} display={{md: 'none'}}>
                        <Button disabled={pagination.pageNumber === 1} format='primary' text='<' onClick={prevPage} />
                    </Grid>

                    <Grid item xs={4} sm={3} display={{md: 'none'}}>
                        <Button disabled={pagination.pageCount === pagination.pageNumber} format='primary' text='>' onClick={nextPage} />
                    </Grid>

                    {/* Pagination Buttons For Medium and Larger */}
                    <Grid item align='center' md={1} display={{xs: 'none', sm: 'none', md: 'block'}}>
                        <Button disabled={pagination.pageNumber === 1} format='primary' text='<' onClick={prevPage} />
                    </Grid>

                    <Grid item align='center' xs={12} md={10}>
                        <Typography component='p' variant='body1'>
                            {"Look at all the videos you've already watched!"}
                        </Typography>
                    </Grid>

                    <Grid item align='center' md={1} display={{xs: 'none', sm: 'none', md: 'block'}}>
                        <Button disabled={pagination.pageCount === pagination.pageNumber} format='primary' text='>' onClick={nextPage} />
                    </Grid>

                    {videoHistory.length && videoHistory.map(video => (
                        <Grid key={video.id} item>
                            <Card
                                actionsNode={{
                                    style: {display: 'flex', justifyContent: 'center'},
                                    display: <Button format='primary' text='Rewatch' onClick={() => rewatchVideo(video)} />
                                }}>
                                <Grid container justifyContent={'center'} alignContent={'center'} direction={'column'}>
                                    <Grid item>
                                        <Typography sx={{fontWeight: 900, color: 'black', textAlign: 'center', fontSize: 14}}>
                                            {video.occupationTitle?.length > 30 ? `${video.occupationTitle.slice(0, 30)}...` : video.occupationTitle}
                                        </Typography>
                                    </Grid>  
                                    <Grid item>
                                      <VideoPlayer addtionalOptions={VIDEO_OPTIONS} additionalVars={VIDEO_PLAYER_VARS} video={{id: video.sourceId, watchId: video.watchId}} viewOnly={true} />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    ))}

                </Grid>
            </>
        )
    }
}

export default VideoHistoryPage
import React from 'react';
import PropTypes from 'prop-types';

import {Circle, Font, Image, Line, Page, StyleSheet, Svg, Text, View, Document} from '@react-pdf/renderer';
import ReactPDFChart from 'react-pdf-charts';
import {Cell, Pie, PieChart} from 'recharts'

import {convertValue} from '../../helpers/results';
import {insightReportLogo} from '../../assets';
import {breakLines, stringToColor} from '../../helpers/strings';

Font.registerEmojiSource({
    format: 'png',
    url: 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple@15.0.1/img/apple/64/'
})

const pageStyles = StyleSheet.create({
    view: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    sectionHeader: {
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        fontSize: 16,
        marginBottom: 10,
    },
    lineDivider: {
        x1: 20,
        y1: 1,
        x2: 575,
        y2: 1,
        strokeWidth: 2,
        stroke: '#DADADA',
        style: {
            paddingLeft: 20,
            paddingRight: 20,
        }
    },
    lineDividerSvg: {
        height: 2,
        width: '100%',
        style: {
            marginTop: 10,
            marginBottom: 10,
        }
    },
    lineTable: {
        x1: 0,
        y1: 1,
        x2: 575,
        y2: 1,
        strokeWidth: 2,
        stroke: '#DADADA',

    },
    lineTableSvg: {
        height: 2,
        width: '100%',
    },
    title: {
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        fontSize: 14,
    },
    detail: {
        fontFamily: 'Helvetica',
        fontWeight: 'normal',
        fontSize: 14,
    },
});

const InsightReport = ({reportData}) => {

    const {user: {firstName, lastName, email}, clusters, occupations} = reportData;

    const buildHeader = () => {
        return (
            <View style={{...pageStyles.view, marginTop: 20, marginBottom: 20, width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'start'}}>
                <Image src={insightReportLogo} style={{height: 20, width:100, marginBottom: 5}}/>
                <Svg {...pageStyles.lineTableSvg}>
                    <Line {...pageStyles.lineTable} />
                </Svg>
            </View>
        )
    }

    const buildFooter = (pageNumber) => {
        return (
            <View style={{position:'absolute', bottom: 10, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', paddingLeft: 20, paddingRight: 20, width:'100%'}}>
                <Text style={{fontSize: 10, color:'#979797'}}>
                    {`Copyright ${new Date().getFullYear()}`}
                </Text>
                <Text style={{fontSize: 10, color:'#979797', width:450}}>
                    From &quot;O*NET OnLine Help: Find Occupations&quot; by the U.S. Department 
                    of Labor, Employment and Training Administration (USDOL/ETA).
                    Used under the CC BY 4.0 license.
                </Text>
                <Text style={{fontSize: 10, textAlign:'right', color:'#979797'}}>
                    {pageNumber}
                </Text>
            </View>
        )
    }

    const graphData = clusters.map(cluster => {
        return {
            name: cluster.name,
            nameSplitArray: breakLines(cluster.name, 15).split('\n'),
            count: cluster.count,
            percent: cluster.percent,
            color: stringToColor(cluster.name)
        }
    });

    return (
        <Document title={`Insight Report - ${firstName} ${lastName} ${new Date().toLocaleDateString()}`} author='FutureGen XYZ'>
            {/*PAGE 1*/}
            <Page style={{fontFamily: 'Helvetica', display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>
                {/* Header */}
                <View style={{...pageStyles.view, width:'100%', height:110, backgroundColor:'#F4F6FC', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'start'}}>
                    <Image src={insightReportLogo} style={{height: 20, width:100, marginBottom: 5}}/>
                    <Text style={{fontFamily:'Helvetica-Bold', fontSize: 28}}>{`${firstName} ${lastName}'s Career Insight Report`}</Text>
                </View>
                {/* User Information */}
                <View style={{...pageStyles.view, display:'flex', flexDirection:'column', marginTop: 20}}>
                    <Text style={pageStyles.sectionHeader}>General Information:</Text>
                    <View style={{display:'flex', flexDirection:'row', gap: 100}}>
                        <View style={{display:'flex', flexDirection:'column', gap: 10}}>
                            <View style={{display:'flex', flexDirection:'row', gap: 5}}>
                                <Text style={pageStyles.title}>Name:</Text>
                                <Text style={pageStyles.detail}>{`${firstName} ${lastName}`}</Text>
                            </View>
                            <View style={{display:'flex', flexDirection:'row', gap: 5}}>
                                <Text style={pageStyles.title}>E-mail:</Text>
                                <Text style={pageStyles.detail}>{email}</Text>
                            </View>
                            <View style={{display:'flex', flexDirection:'row', gap: 5}}>
                                <Text style={pageStyles.title}>Last updated:</Text>
                                <Text style={pageStyles.detail}>{new Date().toLocaleDateString()}</Text>
                            </View>
                        </View>
                        <View style={{display:'flex', flexDirection:'row', gap: 10}}>
                            <Text style={pageStyles.title}>Number of videos watched:</Text>
                            <Text style={pageStyles.detail}>{clusters.reduce(function(prev, cur) {
                                return prev + cur.count;
                                }, 0)}</Text>
                        </View>
                    </View>
                </View>
                <Svg {...pageStyles.lineDividerSvg}>
                    <Line {...pageStyles.lineDivider} />
                </Svg>
                {/* Summary */}
                <View style={{...pageStyles.view, display:'flex', flexDirection:'column'}}>
                    <Text style={pageStyles.sectionHeader}>Summary:</Text>
                    <Text style={pageStyles.detail}>
                        {`${firstName} ${lastName} has been recommended that they continue exploring the following careers based on their demonstrated interests and lifestyle wants on Future Gen’s CareerAtlas. Please help ${firstName} ${lastName} narrow down their career decision so they may begin learning the skill sets necessary to take these interests to the next level and prepare to be ready for the modern workplace.`}
                    </Text>
                </View>
                {/* Chart */}
                <View style={{...pageStyles.view, display:'flex', flexDirection:'row', marginTop: 20}}>
                    <View style={{display:'flex', flexDirection:'column'}}>
                        <Text style={{...pageStyles.title, fontSize: 12}}>Distribution of watched videos</Text>
                        <ReactPDFChart style={{marginTop: 20, marginRight: 15}}>
                            <PieChart width={340} height={300}>
                                <Pie
                                    data={graphData}
                                    isAnimationActive={false}
                                    labelLine={false}
                                    dataKey={'count'}
                                    fill={(entry) => entry.color}
                                    outerRadius={110}
                                    innerRadius={60}
                                    label={({count, percent}) =>
                                        `${count} (${percent}%)`
                                    }>
                                    {graphData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                    </Pie>
                            </PieChart>
                        </ReactPDFChart>
                    </View>
                    <View style={{display:'flex', flexDirection:'column', gap: 10, justifyContent:'center', flexGrow: 1}}>
                        <Text style={{...pageStyles.title, fontSize: 12}}>Career Clusters:</Text>
                        {graphData.sort((a, b) => b.percent - a.percent).map((data, index) => (
                            <View key={index} style={{display:'flex', flexDirection:'row', gap: 5, alignContent: 'center', alignItems: 'center'}}>
                                <Svg height={10} width={10}>
                                    <Circle cx={5} cy={5} r={5} fill={data.color} />
                                </Svg>
                                <View style={{display:'flex', flexDirection:'column', flexGrow: 1}}>
                                    {data.nameSplitArray.map((line, index) => (
                                        <Text key={index} style={{...pageStyles.detail, fontSize: 10}}>{line}</Text>
                                    ))}
                                </View>
                                <Text key={index} style={{...pageStyles.detail, fontSize: 10}}>{`${data.percent}%`}</Text>
                            </View>
                        ))}
                    </View>
                </View>
                {/* Footer */}
                {buildFooter(1)}
            </Page>
            {/*PAGE 2*/}
            <Page style={{fontFamily: 'Helvetica', display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>
                {/* Header */}
                {buildHeader()}
                {/* Career Snapshot */}
                <View style={{...pageStyles.view, display:'flex', flexDirection:'column'}}>
                    <Text style={pageStyles.sectionHeader}>Career Snapshot:</Text>
                    <View style={{backgroundColor:'#DEE6FF', padding: 10, marginBottom: 10}}>
                        <Text>
                            <Text style={{...pageStyles.detail, color: '#595FF3', fontFamily: 'Helvetica-BoldOblique'}}>
                                {'Note: '}
                            </Text>
                            <Text style={{...pageStyles.detail, fontFamily: 'Helvetica-Oblique'}}>
                                Outcomes are subject to change over time. User watch behavior may impact results. Therefore, it&apos;s important to recognize that the information provided is not static and may require periodic reassessment to ensure relevance and accuracy.
                            </Text>
                        </Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'column', gap: 10, border: 'solid', borderRadius: 8, borderWidth: 2, borderColor: '#DADADA', padding:4}}>
                        <View style={{display:'flex', flexDirection:'column', alignContent: 'center', gap: 10}}>
                            <View style={{display:'flex', flexDirection:'row', gap: 10, marginTop: 5, marginLeft: 5, marginRight: 5}}>
                                <Text style={{...pageStyles.detail, color: '#979797', flexGrow: 1}}>Occupations</Text>
                                <Text style={{...pageStyles.detail, color: '#979797', width: 75}}>Match score</Text>
                            </View>
                            <Svg {...pageStyles.lineTableSvg}>
                                <Line {...pageStyles.lineTable} />
                            </Svg>
                        </View>
                        <View style={{display:'flex', flexDirection:'column', gap: 10, marginBottom: 5}}>
                            {occupations.map((occupation, index) => (
                                <View key={index} style={{display:'flex', flexDirection:'column', alignContent: 'center', gap: 5}}>
                                    <View key={index} style={{display:'flex', flexDirection:'row', marginLeft: 5, marginRight: 5}}>
                                        <View style={{display: 'flex', flexDirection: 'row', alignContent: 'center', gap: 10,  ...pageStyles.detail, flexGrow: 1}}>
                                            <Text style={pageStyles.detail}>
                                                {occupation.name}
                                            </Text>
                                            {occupation.fastGrowing && 
                                            <Text style={{...pageStyles.detail}}>
                                                🚀 Fast-growing career
                                            </Text>
                                        }
                                        </View>
                                        <Text style={{...pageStyles.detail, width: 75, textAlign: 'center'}}>{`${convertValue(occupation.rating)}%`}</Text>
                                    </View>
                                    {index < occupations.length - 1 && (
                                        <Svg {...pageStyles.lineTableSvg}>
                                            <Line {...pageStyles.lineTable} />
                                        </Svg>
                                    )}
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
                {/* Footer */}
                {buildFooter(2)}
            </Page>
        </Document>
    );
}

InsightReport.propTypes = {
    reportData: PropTypes.object.isRequired
};

export default InsightReport;
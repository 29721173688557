import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import styled from 'styled-components';

const colors = {
    low: {
        backgroundColor: '#FFEEEE',
        percentColor: '#AF0202',
        barBackgroundColor: '#F68E8E',
    },
    medium: {
        backgroundColor: '#FFFBEE',
        percentColor: '#E36E02',
        barBackgroundColor: '#F6C68E',
    },
    high: {
        backgroundColor: '##F4FFEE',
        percentColor: '#4EAA62',
        barBackgroundColor: '#B2EFBF',
    }
}

export const GlobalTrustMeter = (props) => {
    const {fullAmount = 32, trustAmount = 0, style} = props;

    const remainingAmount = trustAmount === -1 ? fullAmount : fullAmount - trustAmount;
    const trustPercent = remainingAmount <= 0 ? 100 : trustAmount === -1 ? 0 : parseFloat((trustAmount / fullAmount) * 100).toFixed(0);
    const colorProfile = trustPercent < 50 ? colors.low : trustPercent < 71 ? colors.medium : colors.high;

    const StyledLinearProgressBar = styled(LinearProgress, {
        props: {variant: 'determinate'}
      })({
        [`&.${linearProgressClasses.root}`]: {backgroundColor: colorProfile.barBackgroundColor},
        [`&.${linearProgressClasses.determinate}`]: {backgroundColor: colorProfile.barBackgroundColor},
      });

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', ...style, backgroundColor: colorProfile.backgroundColor, color: colorProfile.percentColor, backgroundClip: 'content-box', overflow: 'hidden'}}>
            <div style={{display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems:'center', justifyContent: 'center', gap: 5, width: '100%'}}>
                <Typography sx={{fontSize: 14, fontWeight: 'bold', color: colorProfile.percentColor}}>{trustPercent}%</Typography>
                <Typography sx={{fontSize: 14, color: '#8C8C8D'}}>{remainingAmount <= 0 ? 0 : remainingAmount} more videos until Results are ready</Typography>
            </div>
            <StyledLinearProgressBar variant="determinate" value={trustPercent} color='inherit' sx={{alignSelf: 'flex-end', width: '100%', height: 5}}/>
        </div>
    )
}

// Add prop validation
GlobalTrustMeter.propTypes = {
    fullAmount: PropTypes.number,
    trustAmount: PropTypes.number.isRequired, // Add trustAmount prop validation
    style: PropTypes.object,
};
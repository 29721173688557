import React, {useContext, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthService from '../../services/auth';
import RootContext from '../../services/context-states/root-context';
import TopAppBar from './TopAppBar';
import SideAppBar from './SideAppBar';

import {userApi} from '@apis';

const NavigationBar = ({ignoreLogin}) => {
  const {billingContext, tokenContext} = useContext(RootContext)
  const {billingInformation, setBillingInformation} = billingContext;
  const {token, setToken} = tokenContext;
  const [isLoggedIn, toggleLoggedIn] = useState(false);

  useEffect(() => {

    if(ignoreLogin) {
      return;
    }

    const authToken = AuthService.getCurrentUser()?.token;
    if (!isLoggedIn && authToken) {
      // check if authToken is valid
      userApi.account.verify().then(() => {
        toggleLoggedIn(true);

        if(!token || token.length === 0) {
          setToken(authToken)
        }
      }).catch(() => {
        toggleLoggedIn(false);
        AuthService.logout();
        setToken(null);
      })
    } else if (!authToken && isLoggedIn) {
      toggleLoggedIn(false);
    }
  })

  useEffect(() => {

    if(ignoreLogin) {
      return;
    }

    if(isLoggedIn && token && billingInformation === null) {
      userApi.billing.checkBilling().then((response) => {
        setBillingInformation(response);
      }).catch(() => {
        setBillingInformation({});
      })
    }
  }, [isLoggedIn, billingInformation])

  return (
    <>
      { !isLoggedIn || ignoreLogin ? (
        <TopAppBar />
      ) : (
          <SideAppBar />
      ) }

      <Outlet />
    </>
  )
}
NavigationBar.propTypes = {
  ignoreLogin: PropTypes.bool
}

export default NavigationBar
const ROUND_HOVER_CHANGE_BUTTON = {
    fontSize: '1.5rem',
    minWidth: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    backgroundColor: 'primary.contrast',
    color: 'primary.main',
    border: '2px solid',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.contrast',
        backgroundColor: 'primary.main',
        color: 'primary.contrast'
    }
}

const ROUND_HOVER_CHANGE_BUTTON_INVERSE = {
    fontSize: '1.5rem',
    minWidth: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    backgroundColor: 'primary.main',
    borderColor: 'primary.main',
    color: 'primary.contrast',
    border: '2px solid',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.main',
        backgroundColor: 'primary.contrast',
        color: 'primary.main'
    }
}

const ROUND_HOVER_CHANGE_ICON_BUTTON = {
    backgroundColor: 'primary.main',
    color: 'primary.contrast',
    border: '2px solid',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.main',
        backgroundColor: 'primary.contrast',
        color: 'primary.main'
    }
}

// Use on light backrounds so border does not get lost
const ROUND_HOVER_CHANGE_ICON_BUTTON_LIGHT = {
    backgroundColor: 'primary.main',
    color: 'primary.contrast',
    border: '2px solid',
    borderColor: 'primary.main',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.main',
        backgroundColor: 'primary.contrast',
        color: 'primary.main'
    }
}

// Use on dark backgrounds because icon's white border needs to show
const ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_DARK = {
    backgroundColor: 'primary.contrast',
    color: 'primary.main',
    border: '2px solid',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.contrast',
        backgroundColor: 'primary.main',
        color: 'primary.contrast'
    }
}

// Use on light backgrounds because the border needs to show
const ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_LIGHT = {
    backgroundColor: 'primary.contrast',
    color: 'primary.main',
    border: '2px solid',
    borderColor: 'primary.main',
    ':hover': {
        border: '2px solid',
        borderColor: 'primary.main',
        backgroundColor: 'primary.main',
        color: 'primary.contrast'
    }
}

export {
    ROUND_HOVER_CHANGE_BUTTON,
    ROUND_HOVER_CHANGE_BUTTON_INVERSE,
    ROUND_HOVER_CHANGE_ICON_BUTTON,
    ROUND_HOVER_CHANGE_ICON_BUTTON_LIGHT,
    ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_DARK,
    ROUND_HOVER_CHANGE_ICON_BUTTON_INVERSE_LIGHT
}
import React from 'react'
import {Controller} from 'react-hook-form';
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'

import TextField from '@mui/material/TextField';

/* Passthroughs should be left for the following:
- variant
- fullWidth
- size
*/

export const GlobalFormInput = (props) => {
  const {name, control, id, label, rules, ...passthroughProps} = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: {onChange, value},
        fieldState: {error},
      }) => (
        <TextField
          id={id}
          label={label || startCase(id)}
          name={name}
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value}
          {...passthroughProps}
        />
      )}
    />
  )
}

GlobalFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.any,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object
}
